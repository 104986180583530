import { Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import ApiService from "../../api/api.service";
import { StoreState } from "../store.state";
import { toast } from "react-toastify";
import PlumAnalytics from "../../utils/analytics/index";
import { CART_ACTION_TYPE } from "../../commons/constants/app.constants";

import {
  updateVoucherCartProducts,
  addVoucherToCart,
  addToCartError,
  fetchingCartData,
  fetchingCartError,
  updateVoucherQuantity,
  removeVoucherFromCart,
  redirectToCheckout,
  conversionToBase,
  cartConversionToInr,
} from "../cart/actions";
import { VoucherCartItem } from "./types";
import isEmpty from "lodash/isEmpty";

const payTMprodId = process.env.REACT_APP_PAYTM_ID;
const experienceCategoryId = process.env.REACT_APP_GV_EXPERIENCE_CATEGORY_ID;
let category = new URLSearchParams(window.location.search).get("category");

const gtmAction = (event: string, voucher: any) => {
  let method = event === "removeFromCart" ? "remove" : "add";

  let gtmData = {
    event: event,
    ecommerce: {
      currencyCode: (voucher && voucher.currency) || "INR",
      [method]: {
        products: [
          {
            id: voucher.productId || "",
            name: voucher.name || "",
            price: voucher.price || "",
            // "brand": voucher.name || "",
            // "category": cartValue && cartValue.info && cartValue.info.productInfo && cartValue.info.productInfo.categories || "",
            quantity: voucher.quantity || "",
          },
        ],
      },
    },
  };

  PlumAnalytics.gtmAction(gtmData);
};

export const addToCartByThunk =
  (
    voucher: VoucherCartItem,
    actionType: string = ""
  ): ThunkAction<void, StoreState, null, Action<string>> =>
  async (dispatch: Dispatch, getState: () => StoreState): Promise<void> => {
    try {
      dispatch(fetchingCartData(true));
      const {
        user: { isLoggedIn = false } = {},
        cart: {
          entities: { vouchers = [] },
        },
      } = getState();

      let allEentries = vouchers;

      let voucherInfo = voucher && voucher.info ? JSON.parse(voucher.info) : {};
      const {
        currency_code,
        spl_discount,
        spl_charge,
        currency_id,
        currency_name,
        conversion_ratio,
      } = voucherInfo;

      if (voucherInfo) {
        if (voucherInfo.important_info) {
          voucherInfo.special_redemption = 1;
        } else {
          voucherInfo.special_redemption = 0;
        }
      }

      if (!isEmpty(voucherInfo)) {
        delete voucherInfo.description;
        delete voucherInfo.how_to_use;
        delete voucherInfo.inner_image;
        delete voucherInfo.how_to_redeem_image;
        delete voucherInfo.terms_conditions;
        delete voucherInfo.expiry_and_validity;
        delete voucherInfo.important_info;
      }

      voucherInfo = JSON.stringify(voucherInfo);
      voucher.info = voucherInfo;

      const prouductDiscount = spl_discount - spl_charge;

      const cartValue: any = {
        product_id: voucher.productId,
        type: "voucher",
        status: actionType === CART_ACTION_TYPE.REMOVE_CART ? 0 : 1,
        info: {
          quantity: voucher.quantity,
          point: voucher.point,
          denomination: currency_code + " " + voucher.price,
          final_price: (voucher.price * (100 - prouductDiscount)) / 100,
          base_conversion_value: voucher.baseConversionValue,
          product_discount: prouductDiscount,
          product_discount_inr:
            (prouductDiscount * voucher.price) / (conversion_ratio * 100),
          currency_code: currency_code,
          currency_value: conversion_ratio,
          currency_id: currency_id,
          currency_name: currency_name,
          productInfo: JSON.parse(voucherInfo),
        },
      };

      if (actionType === CART_ACTION_TYPE.UPDATE_CART) {
        cartValue.cart_update = 1;
      }

      const triggerAction = () => {
        const itemConst =
          vouchers.length > 0 || voucher.quantity > 1 ? "Items" : "Item";
        if (actionType === CART_ACTION_TYPE.ADD_CART) {
          //check for paytm product
          let isItemExist: boolean = false;
          if (
            allEentries &&
            allEentries.find((d: any) => d.productId == payTMprodId) !=
              undefined
          ) {
            isItemExist = true;
          } else if (voucher.quantity > 1 || isItemExist) {
            isItemExist = true;
          } else {
            isItemExist = false;
          }
          if (
            voucher &&
            voucher.productId == Number(payTMprodId) &&
            isItemExist
          ) {
            toast.error(
              `You're not allowed to add multiple Paytm products into the cart.`,
              { autoClose: 2000 }
            );
          } else {
            dispatch(addVoucherToCart(voucher));
            gtmAction("addToCart", voucher);
            toast.success(`${itemConst} added`, { autoClose: 2000 });
          }
        } else if (actionType === CART_ACTION_TYPE.UPDATE_CART) {
          dispatch(updateVoucherQuantity(voucher));
          toast.success("Quantity updated", { autoClose: 2000 });
        } else if (actionType === CART_ACTION_TYPE.REMOVE_CART) {
          dispatch(removeVoucherFromCart(voucher));
          gtmAction("removeFromCart", voucher);
          toast.success("Item removed from Cart", { autoClose: 2000 });
        } else if (actionType === CART_ACTION_TYPE.BUY_NOW) {
          let isItemExist: boolean = false;
          if (
            allEentries &&
            allEentries.find((d: any) => d.productId == payTMprodId) !=
              undefined
          ) {
            isItemExist = true;
          } else if (voucher.quantity > 1 || isItemExist) {
            isItemExist = true;
          } else {
            isItemExist = false;
          }
          if (
            voucher &&
            voucher.productId == Number(payTMprodId) &&
            isItemExist
          ) {
            toast.error(
              `You're not allowed to add multiple Paytm products into the cart.`,
              { autoClose: 2000 }
            );
          } else {
            dispatch(addVoucherToCart(voucher));
            gtmAction("addToCart", voucher);
            toast.success(`${itemConst} added`, { autoClose: 2000 });
            dispatch(redirectToCheckout(true));
          }
        }
        // dispatch(fetchingCartData(false))
        setTimeout(() => {
          dispatch(fetchingCartData(false));
          dispatch(redirectToCheckout(false));
        }, 300);
      };

      if (isLoggedIn) {
        const cartResponse = await ApiService.addToCart({
          cart: JSON.stringify(cartValue),
        });
        if (
          cartResponse &&
          cartResponse.addToCart &&
          cartResponse.addToCart.success &&
          cartResponse.addToCart.success === 1
        ) {
          triggerAction();
        } else {
          dispatch(
            addToCartError(cartResponse.data && cartResponse.addToCart.message)
          );
          toast.error(
            cartResponse.addToCart.Message ||
              cartResponse.addToCart.message ||
              "Something went wrong",
            { autoClose: 2000 }
          );
        }
      } else {
        triggerAction();
      }
    } catch (error: any) {
      dispatch(addToCartError(error.message));
      dispatch(fetchingCartData(false));
    }
  };

export const fetchCartWithThunk =
  (
    variables: any,
    specialCategory = category === "experience" ? true : false
  ): ThunkAction<void, StoreState, null, Action<string>> =>
  async (dispatch: Dispatch, getState: () => StoreState): Promise<void> => {
    try {
      const {
        cart: { entities: { vouchers: existingVouchers = [] } = {} } = {},
      } = getState();

      dispatch(fetchingCartData(true));
      const cartApiData = await ApiService.fetchCart(variables);

      if (
        cartApiData.fetchCart &&
        cartApiData.fetchCart.data &&
        cartApiData.fetchCart.success === 1
      ) {
        let cartData: any = cartApiData.fetchCart && cartApiData.fetchCart.data;

        const disabledProductIds = cartData
          .filter((element: any) => element.availability === 0)
          .map((ele: any) => ele.product_id);

        if (cartData.length > 0) {
          let newCartArray: any = [];
          cartData.forEach(function (voucher: any, index: number, data: any) {
            const voucherInfo =
              voucher && voucher.info !== "" ? JSON.parse(voucher.info) : {};
            const price = voucherInfo && voucherInfo.denomination.split(" ")[1];
            const denomination = parseInt(price);
            // const categoriesArray = voucherInfo?.categories
            //   ? voucherInfo.categories.split(",")
            //   : [];
            const categoriesArray = voucherInfo?.productInfo?.categories
              ? voucherInfo.productInfo.categories.split(",")
              : [];

            //Prepare cart compatible
            const frontendCartInfo = {
              productId: voucherInfo.productInfo.product_id,
              price: denomination,
              quantity: voucherInfo.quantity,
              currency: voucherInfo.currency_code,
              name: voucherInfo.productInfo.name,
              info: JSON.stringify(voucherInfo.productInfo),
            };
            newCartArray.push(frontendCartInfo);
          });

          let activeExistingProducts = [...newCartArray].filter((existItem) => {
            return disabledProductIds.includes(existItem.productId) === false;
          });
          dispatch(updateVoucherCartProducts(activeExistingProducts));
        }
        const conversion_to_base = cartApiData.fetchCart.conversion_to_base;
        const cart_conversion_to_inr = cartApiData.fetchCart.conversion_to_inr;
        dispatch(conversionToBase(conversion_to_base));
        dispatch(cartConversionToInr(cart_conversion_to_inr));
        dispatch(fetchingCartData(false));
      } else {
        dispatch(updateVoucherCartProducts([]));
        dispatch(fetchingCartData(false));
      }
    } catch (error: any) {
      dispatch(fetchingCartError(error.message));
    }
  };
