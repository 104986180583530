import React from "react";
import "../search.scss";
import { getColorFromTheme, stripHtml } from "../../../../../../utils/helpers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import HeaderMenuDropdown from "../../HeaderMenuDropdown";
import { Typography } from "@plum-xoxo/stores-dsm";
import PlumLink from "../../../../Elements/PlumLink/index";

interface SuggestionsType {
  searchData: object[];
  isSearching: boolean;
  setSearchedKey: any;
}

interface state {}

class SearchResults extends React.Component<
  SuggestionsType & RouteComponentProps,
  state
> {
  renderSearchItems() {
    const { searchData } = this.props;
    return (
      <HeaderMenuDropdown
        customWidth={410}
        top={40}
        type="search"
        customClass="searchsuggest p-2"
      >
        {searchData.map((item: any, index: number) => (
          <PlumLink url={{ pathname: item.url, state: {} }}>
            <Typography
              className="searchResult"
              textType="body"
              size="S"
              color={getColorFromTheme("grey", "900")}
            >
              <div
                className="d-flex justify-content-between align-items-center cursor-pointer"
                style={{ padding: "12px", columnGap: 36 }}
              >
                <div className="d-flex" style={{ maxWidth: "55%" }}>
                  <div id="search-results" className="showTwoline">
                    {item.url.includes("vouchers/") ? (
                      stripHtml(item.name)
                    ) : (
                      <Typography
                        textType="body"
                        size="S"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.name.charAt(0).toUpperCase() +
                          item.name.substr(1).toLowerCase()}
                      </Typography>
                    )}
                  </div>
                </div>
                <Typography
                  textType="body"
                  size="S"
                  color={getColorFromTheme("primary", "300")}
                >{`In ${
                  item.type && item.type === "experience"
                    ? "Experience"
                    : "GC/Merch"
                }`}</Typography>
              </div>
            </Typography>
          </PlumLink>
        ))}
      </HeaderMenuDropdown>
    );
  }

  render() {
    const { searchData, isSearching } = this.props;
    return (
      <React.Fragment>
        {searchData &&
          (isSearching === true ? (
            <HeaderMenuDropdown
              customWidth={410}
              top={40}
              type="search"
              customClass="searchsuggest p-2"
            >
              <div
                className="d-flex justify-content-between align-items-center cursor-pointer"
                style={{ padding: "12px" }}
              >
                <Typography textType="body" size="S">
                  Searching...
                </Typography>
              </div>
            </HeaderMenuDropdown>
          ) : (
            this.renderSearchItems()
          ))}
      </React.Fragment>
    );
  }
}
export default withRouter(SearchResults);
