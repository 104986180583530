import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { StoreState } from "../../../../../store/store.state";
import { getUserCountryName } from "../../../../../store/appInfo/getters";
import {
  setCountryList,
  setUserCountry,
  setSelectedCountry,
  setUserCountryCode,
  setOriginCountryCode,
  setVoucherCategoryList,
} from "../../../../../store/appInfo/actions";
import CountryDropdown from "../ActionHeader/CountryDropdown";
import {
  getUrlWithBranding,
  isLengthyArray,
} from "../../../../../utils/helpers";
import MobileCountrySelection from "../../../../../commons/components/MobileNavigation/Components/MobileCountrySelection";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface State {}

interface Props {
  countryList: any;
  userCountryName: string;
  setCountryList: Function;
  setUserCountry: Function;
  setOriginCountryCode?: any;
  setUserCountryCode?: any;
  setSelectedCountry?: any;
  userCountryCode?: string;
  userCountry?: number;
  isLoggedIn?: boolean;
  customUrl?: string;
  originCountryCode?: string;
  voucherCountryList?: any;
  isMobileView?: boolean;
  appInfo: any;
  setVoucherCategoryList: any;
  isMobile: boolean;
  showCountryModal?: boolean;
  handleBack?: any;
  history?: any;
}

class SelectCountry extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    const { voucherCountryList } = this.props;
    this.setCountryListInStore(voucherCountryList);
  }

  componentDidUpdate(_prevProps: Props, _prevState: State) {
    const {
      setUserCountry,
      setUserCountryCode,
      setSelectedCountry,
      originCountryCode,
      setOriginCountryCode,
      userCountry,
      countryList,
      userCountryName,
    } = this.props;

    if (userCountry !== _prevProps.userCountry) {
      this.setState({ userCountry });
    }

    if (userCountryName !== _prevProps.userCountryName) {
      this.setState({ userCountryName });
    }

    if (countryList !== _prevProps.countryList) {
      this.setState({ countryList });
    }

    if (_prevProps.originCountryCode !== originCountryCode) {
      let selectedCountry = countryList.find(
        (item: any) => item.iso_code_2 === originCountryCode
      );
      if (selectedCountry) {
        setUserCountry(selectedCountry.country_id);
        setSelectedCountry(selectedCountry);
      }
      setUserCountryCode(originCountryCode);
      setOriginCountryCode(originCountryCode);
    }
  }

  setCountryListInStore = (countryListForProduct: any = []) => {
    const {
      userCountryCode,
      setUserCountry,
      setUserCountryCode,
      setSelectedCountry,
      setCountryList,
      originCountryCode,
    }: any = this.props;

    if (countryListForProduct.length > 0) {
      const countryCode = userCountryCode ? userCountryCode : originCountryCode;
      let selectedCountry = countryListForProduct.find(
        (item: any) => item.iso_code_2 === countryCode
      );
      if (!selectedCountry) {
        selectedCountry = countryListForProduct[0];
      }

      setUserCountry(selectedCountry.country_id);
      setUserCountryCode(selectedCountry.iso_code_2);
      setSelectedCountry(selectedCountry);

      setCountryList(countryListForProduct);
    }
  };

  handleCountrySelection = (data: any, isMobile: boolean = false): void => {
    const {
      setUserCountry,
      setSelectedCountry,
      setUserCountryCode,
      countryList,
      appInfo: { branding: { custom_url = "/" } = {} } = {},
    } = this.props;

    let selectedCountry =
      countryList &&
      countryList.find((item: any) => item.iso_code_2 === data.iso_code_2);

    setUserCountry(data.country_id || 99);
    setSelectedCountry(selectedCountry || "India");
    setUserCountryCode((selectedCountry && selectedCountry.iso_code_2) || "IN");
    if (isMobile) {
      const pathname = window.location.pathname.split("/")[2];
      const source = new URLSearchParams(window.location.search).get("source");
      if (pathname === "experiences" || source === "experiences") {
        this.props.history.push(
          getUrlWithBranding(custom_url) + `/experiences`
        );
      } else {
        this.props.history.push(getUrlWithBranding(custom_url) + "/vouchers");
      }
    }
  };

  render() {
    const {
      selectedCountry,
      countryList,
      isMobileView,
      isLoggedIn,
      appInfo,
      setVoucherCategoryList,
      userCountryName = "",
      isMobile = false,
      showCountryModal = false,
    }: any = this.props;

    const countryName = selectedCountry
      ? selectedCountry.name
      : userCountryName;
    if (!isMobile) {
      if (isLengthyArray(countryList)) {
        return (
          <CountryDropdown
            countryName={countryName}
            handleCountrySelection={this.handleCountrySelection}
            countryData={countryList}
            isLoggedIn={isLoggedIn}
            appInfo={appInfo}
            setVoucherCategoryList={setVoucherCategoryList}
          />
        );
      }
    } else {
      return (
        <MobileCountrySelection
          showCountryModal={showCountryModal}
          handleBack={() => this.props.handleBack()}
          handleCountrySelection={this.handleCountrySelection}
          countryData={countryList}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = ({ appInfo, user }: StoreState) => ({
  countryList: appInfo.countryList,
  voucherCountryList: appInfo.voucherCountryList,
  userCountry: appInfo.userCountry,
  userCountryName: getUserCountryName(appInfo),
  userCountryCode: appInfo.userCountryCode,
  isLoggedIn: user.isLoggedIn,
  selectedCountry: appInfo.selectedCountry,
  originCountryCode: appInfo.originCountryCode,
  customUrl: appInfo.branding.custom_url,
  appInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCountryList: (countryList: any) => setCountryList(countryList),
      setUserCountry: (countryId: number) => setUserCountry(countryId),
      setUserCountryCode: (countryCode: string) =>
        setUserCountryCode(countryCode),
      setSelectedCountry: (selectedCountry: any) =>
        setSelectedCountry(selectedCountry),
      setOriginCountryCode: (code: string) => setOriginCountryCode(code),
      setVoucherCategoryList: (voucherCategoryList: any) =>
        setVoucherCategoryList(voucherCategoryList),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectCountry);
