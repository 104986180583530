import React from "react";
import { ModalBody, Input } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { Typography, Button } from "@plum-xoxo/stores-dsm";
import ApiService from "../../../../../api/api.service";
import { getColorFromTheme } from "../../../../../utils/helpers";
import { Turnstile } from "@plum-xoxo/turnstile_captcha";
import { TURNSTILE_SITE_KEY } from "../../../../constants";

interface Props {
  showmodal: Function;
}
interface State {
  btnstate: boolean;
  inputVal: string;
  amount: number;
  remain_amount: number;
  expiry_date: string;
  isLoading: boolean;
  isCaptchaClicked: number;
  currency: string;
  currency_value: number;
  inputChangeFlag: boolean;
  url: string;
  incorrect: boolean;
  renderTurnstileCaptcha: boolean;
}

class VoucherDetails extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      btnstate: true,
      inputVal: "",
      amount: 0,
      remain_amount: 0,
      expiry_date: "",
      isLoading: false,
      isCaptchaClicked: 0,
      currency: "",
      currency_value: 1,
      inputChangeFlag: false,
      url: "",
      incorrect: false,
      renderTurnstileCaptcha: false,
    };
  }

  toggle = () => {
    this.props.showmodal();
  };

  handlechange = (value: string) => {
    this.setState({ inputVal: value, inputChangeFlag: true });
    if (!value || value.length < 6) {
      this.setState({ btnstate: true });
      return;
    } else {
      this.setState({ btnstate: false });
    }
  };

  resetInput = () => {
    this.setState({ incorrect: false, inputVal: "" });
  };

  getCfToken = () => {
    this.setState({ renderTurnstileCaptcha: true });
  };

  getVoucherDetails = ({ token = "" }) => {
    this.setState({ isLoading: true });
    let reqParams: any = {
      code: this.state.inputVal
        ? this.state.inputVal.split("-").join("").trim()
        : "",
      partner_type: "vd",
      token,
    };

    try {
      ApiService.applyVoucher(reqParams).then((resData: any) => {
        this.setState({ renderTurnstileCaptcha: false });
        if (
          resData &&
          resData.success &&
          resData.data &&
          resData.data.Error === false
        ) {
          resData = resData.data;

          let detail_voucher: any =
            resData.Result && resData.Result.voucher_details;

          let url: string = resData.Result && resData.Result.url;

          let currency: string =
            resData.Result &&
            resData.Result.currency &&
            resData.Result.currency[0]?.code
              ? resData.Result.currency[0].code
              : "INR";

          let currency_value =
            resData.Result && resData.Result.currency[0]?.currency_value
              ? resData.Result.currency[0].currency_value
              : 1;

          if (resData.Result && resData.Result.stores === false) {
            toast.error(
              "Please check the campaign URL applicable under ‘Check Xoxo Code Balance’ in the top right corner of your screen"
            );
          } else if (
            resData.Result &&
            detail_voucher &&
            detail_voucher.status === 1
          ) {
            let voucherBalance: number = parseFloat(
              detail_voucher.remain_amount
            );
            if (isNaN(voucherBalance)) {
              toast.error("something went wrong !");
              return;
            } else if (voucherBalance === 0) {
              toast.error("The Xoxo code entered has already been used");
              return;
            }

            this.setState({
              amount: detail_voucher["amount"],
              remain_amount: detail_voucher.remain_amount,
              expiry_date: detail_voucher.expiry_date,
              currency: currency,
              currency_value: currency_value,
              inputChangeFlag: false,
              url: url,
              incorrect: false,
            });
          } else if (resData.Result && detail_voucher) {
            this.setState({
              expiry_date: detail_voucher.expiry_date,
              inputChangeFlag: false,
              remain_amount: detail_voucher.remain_amount,
              currency: currency,
              currency_value: currency_value,
            });

            let messageObject: any = {
              "0": "Your Xoxo Code/ Gift Card is Invalid. For more details please contact cs@xoxoday.com",
              "2": "The Xoxo code entered has already been used",
              "3": "The Xoxo Code entered is no longer valid as it has crossed the expiry date",
              "4": "The Xoxo code entered has been cancelled. Please write to cs@xoxoday.com for further assistance.",
            };

            // toast.error(messageObject[status]);
          }
        } else {
          this.setState({ incorrect: true, isLoading: false });
        }
        this.setState({ isLoading: false, isCaptchaClicked: 0 });
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      btnstate,
      inputVal = "",
      remain_amount = 0,
      expiry_date = "",
      isLoading,
      currency,
      inputChangeFlag,
      currency_value,
      incorrect,
      renderTurnstileCaptcha,
    } = this.state;

    return (
      <>
        <ModalBody>
          <Typography
            className="mb-1"
            textType="body"
            size="S"
            color={getColorFromTheme("grey", "900")}
          >
            Enter your code here
          </Typography>
          <div className="d-flex align-items-center" style={{ columnGap: 12 }}>
            <div>
              <Input
                type="text"
                placeholder="Your Xoxo Code goes here"
                className="codeValue"
                value={
                  inputVal?.length
                    ? inputVal
                        .split("-")
                        .join("")
                        .match(/.{1,4}/g)
                        ?.join("-")
                    : ""
                }
                style={{
                  border: "1px solid #DCDCDC",
                  width: "320px",
                  borderRadius: "4px",
                }}
                maxLength={16}
                onChange={(e) => this.handlechange(e.target.value.toString())}
              />
            </div>
            <div>
              <Button
                disabled={btnstate || isLoading}
                isLoading={isLoading}
                onClick={() => {
                  if (!isLoading) {
                    // this.resetInput();
                    this.getCfToken();
                  }
                  return;
                }}
                color="secondary"
                variant="outlined"
              >
                Check
              </Button>
            </div>
          </div>
          {renderTurnstileCaptcha ? (
            <Turnstile
              siteKey={TURNSTILE_SITE_KEY}
              onSuccess={(token: any) => this.getVoucherDetails({ token })}
              options={{ theme: "light" }}
              style={{ width: "100%" }}
            />
          ) : null}

          {!renderTurnstileCaptcha ? (
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginTop: 32 }}
            >
              <div>
                {incorrect ? (
                  <Typography
                    textType="body"
                    size="M"
                    color={getColorFromTheme("red", "400")}
                  >
                    The Xoxocode entered is invalid!
                  </Typography>
                ) : null}
                {new Date(expiry_date) < new Date() ? (
                  <Typography
                    textType="body"
                    size="M"
                    color={getColorFromTheme("red", "400")}
                  >
                    The Code has expired on{" "}
                    {moment(expiry_date).format("DD/MM/YYYY")}
                  </Typography>
                ) : null}
                {inputVal && !inputChangeFlag ? (
                  <div className="d-flex align-items-center">
                    <Typography
                      className="me-1"
                      textType="body"
                      size="M"
                      color={getColorFromTheme("grey", "900")}
                    >
                      Balance for code entered is:
                    </Typography>
                    <Typography
                      className="me-1"
                      textType="body"
                      fontWeight="medium"
                      size="M"
                      color={getColorFromTheme("grey", "900")}
                    >
                      {currency}
                    </Typography>
                    <Typography
                      textType="body"
                      fontWeight="medium"
                      size="M"
                      color={getColorFromTheme("green", "300")}
                    >
                      {parseFloat((remain_amount * currency_value).toFixed(2))}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div>
                {incorrect ||
                new Date(expiry_date) < new Date() ||
                (inputVal && !inputChangeFlag) ? (
                  <Typography
                    className="cursor-pointer"
                    textType="body"
                    size="S"
                    color={getColorFromTheme("grey", "900")}
                    fontDecoration="underline"
                    onClick={() => this.resetInput()}
                  >
                    Check another
                  </Typography>
                ) : null}
              </div>
            </div>
          ) : null}
        </ModalBody>
      </>
    );
  }
}
export default VoucherDetails;
