import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { GTM_ID, GTM_VISITOR_LOG } from "./commons/constants/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
const webengage_key = process.env.REACT_APP_WEBENGAGE_KEY || "~1341059a5";

const tagManagerArgs = {
  gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
