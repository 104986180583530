import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { fetchCartWithThunk } from "./store/cart/thunks";
import { version } from "../package.json";
import { resetCart } from "./store/cart/actions";
import ApiService from "./api/api.service";
import { StoreState } from "./store/store.state";
import {
  getUserDetails,
  getLoginStatus,
  getUserPoints,
} from "./store/user/getters";
import {
  setUserDetails,
  resetUserData,
  setLoginStatus,
  setUserPoints,
  setGCB,
  setIsAdmin,
  setXoxoTokens,
  setUserCompany,
} from "./store/user/actions";
import invert from "invert-color";
import _startCase from "lodash/startCase";
import {
  deleteCookie,
  getCookieToken,
  STORAGE,
  setGlobalCookie,
} from "./utils/helpers";
import {
  LOGIN_COOKIE_NAME,
  DEFAULT_PLATFORM_SETTINGS,
} from "./commons/constants";

import PageLoader from "./commons/components/Loader/PageLoader";
import {
  getBranding,
  getRoutes,
  getPlatFormSettings,
} from "./store/appInfo/getters";
import {
  setBranding,
  setRoutes,
  // setPerksCountryList,
  setExperienceCountryList,
  setVoucherCountryList,
  setCountryList,
  setPlatformSettings,
  setVoucherCategoryList,
  setExperienceCategoryList,
  setShowAlternateEmail,
  setLinkedAccounts,
  toggleLinkedAccountsModal,
  setShowMultiLanguage,
  setPhnNumDisableData,
  setUserJourneyState,
} from "./store/appInfo/actions";
import { Points, GCB, Tokens, CompanyDetails } from "./store/user/types";

import {
  setUserCountry,
  setSelectedCountry,
  setUserCountryCode,
  setOriginCountryCode,
} from "./store/appInfo/actions";
import {
  PLUM_ROUTES,
  DEFAULT_PREFIX,
} from "./commons/constants/routes.constants";
const theme_prefix = process.env.REACT_APP_WHITE_COLOR_PREFIX;
interface AppProps {
  userDetails: any;
  isLoggedIn: boolean;
  setLoginStatus: (status: boolean) => void;
  setUserDetails: (details: any) => void;
  setBranding: (branding: any) => void;
  setRoutes: (routes: any) => void;
  setUserPoints: (points: Points) => void;
  setGCB: (gcbObgect: GCB) => void;
  resetUserData: Function;
  setIsAdmin: (isAdmin: boolean) => void;
  branding: any;
  originCountryCode: string;
  setXoxoTokens: (tokens: Tokens) => void;
  setPlatformSettings: any;
  setVoucherCategoryList: any;
  setExperienceCategoryList: any;
  setUserCompanyDetails: (company: CompanyDetails) => void;
  setShowAlternateEmail: (showAlternateEmail: boolean) => void;
  setPhnNumDisableData: (disablePhoneNumberInCheckout: boolean) => void;
  platFormSettings?: any;
  fetchCartWithThunk: Function;
  resetCart: Function;
  userCountry: any;
  appInfo: any;
  setLinkedAccounts: (accountsData: any) => void;
  toggleLinkedAccountsModal: (data: any) => void;
  setShowMultiLanguage: (showMultiLanguage: boolean) => void;
  setUserJourneyState: (userJourneyUseCase: any) => void;
}

interface AppState {
  isLoading: boolean;
  custom_url: String;
  custom_route: any;
  logo_url: string;
  routes: any;
  check_routes: any;
  postpaid: any;
  isFetchingUser: any;
  urlParams: string;
}
const ROUTE_NAME = {
  EXPERIENCES: "experiences",
  VOUCHERS: "vouchers",
  PERKES: "perks",
};
class Root extends React.Component<AppProps, AppState> {
  custom_url: string;
  isLogin: any;
  constructor(props: AppProps) {
    super(props);
    this.custom_url = "/";
    this.isLogin = getCookieToken(LOGIN_COOKIE_NAME) === "1" ? true : false;
  }
  state = {
    landingDetails: "",
    // logo_url: STORAGE('logo_url') ? STORAGE('logo_url') : '',
    custom_url: "/",
    routes: [],
    check_routes: 0,
    postpaid: 0,
    isLoading: false,
    custom_route: "",
    logo_url: "",
    isFetchingUser: false,
    urlParams: window.location.search,
  };
  /**
   * Maki get api call to server
   * success response wll have "country_code": "some value".
   * use this to get countryCode from countryList present in redux
   * set this countryCode as userCountry in redux
   */

  setUserCountryFromIp = (apiResponse: any, countryList: any = []) => {
    const {
      setUserCountry,
      setUserCountryCode,
      setSelectedCountry,
      setOriginCountryCode,
    }: any = this.props;

    if (apiResponse && apiResponse.success === 1 && apiResponse.country_code) {
      let selectedCountry = countryList.find(
        (item: any) => item.iso_code_2 === apiResponse.country_code
      );
      //originCountryCode !== apiResponse.country_code &&
      if (!selectedCountry) {
        selectedCountry = countryList[0];
      }
      if (
        sessionStorage.getItem("ipCountryCode") &&
        sessionStorage.getItem("ipCountryCode") ===
          selectedCountry.iso_code_2 &&
        sessionStorage.getItem("userCountryCode") &&
        sessionStorage.getItem("userCountryCode") !== selectedCountry.iso_code_2
      ) {
        selectedCountry = countryList.find(
          (item: any) =>
            item.iso_code_2 === sessionStorage.getItem("userCountryCode")
        );
        if (!selectedCountry) {
          selectedCountry = countryList.find(
            (item: any) =>
              item.iso_code_2 === sessionStorage.getItem("ipCountryCode")
          );
          sessionStorage.setItem("userCountryCode", "");
        }
      } else {
        // STORAGE('ipCountryCode',selectedCountry.iso_code_2)
        sessionStorage.setItem("ipCountryCode", selectedCountry.iso_code_2);
      }

      // STORAGE('userCountryCode')
      setUserCountry(selectedCountry.country_id);
      setSelectedCountry(selectedCountry);
      setUserCountryCode(selectedCountry.iso_code_2);
      setOriginCountryCode(apiResponse.country_code);
    } else {
      let selectedCountry = countryList[0];
      setUserCountry(selectedCountry.country_id);
      setSelectedCountry(selectedCountry);
      setUserCountryCode(selectedCountry.iso_code_2);
    }
  };
  setVoucherCountryData(voucherCountryList: any = []) {
    const { setVoucherCountryList }: any = this.props;
    let countryListSort = voucherCountryList.sort((a: any, b: any) => {
      a = a.filter_value.toLowerCase();
      b = b.filter_value.toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    });
    let countryList = countryListSort.map((item: any) => {
      item.country_id = item.filter_value_id;
      item.name = item.filter_value;
      delete item.filter_value;
      delete item.filter_value_id;
      return item;
    });
    setVoucherCountryList(countryList);
  }
  //Store perks country in redux
  // setPerksCountryData(countryData: any) {
  // 	const { setPerksCountryList }: any = this.props;
  // 	let countryList = countryData.sort((a: any, b: any) => {
  // 		a = a.name.toLowerCase();
  // 		b = b.name.toLowerCase();
  // 		return (a < b) ? -1 : (a > b) ? 1 : 0;
  // 	})
  // 	countryList = countryData.map((item: any) => {
  // 		item.iso_code_2 = item.iso
  // 		delete item.iso
  // 		return item;
  // 	})
  // 	setPerksCountryList(countryList);
  // }
  fetchAppData = (routeList: any = []) => {
    this.setState({ isLoading: true });
    const {
      isLoggedIn,
      setExperienceCountryList,
      setDefaultCountryList,
      branding: { custom_url: customUrl = "/" } = {},
      setVoucherCategoryList,
      setExperienceCategoryList,
      setVoucherCountryList,
      // setPerksCountryList,
      appInfo: {
        userCountryCode = "",
        userCountry = "",
        voucherCountryList = [],
        selectedCountry: { iso_code_2 = "IN" } = {},
      } = {},
    }: any = this.props;

    let voucherCountry = voucherCountryList.find((x: any) => {
      return x.iso_code_2 === userCountryCode;
    });
    // if (!voucherCountry){
    // 	let exp = appInfo.experienceCountryList.find((x: any) => {
    // 		return userCountry === x.country_id
    // 	})
    // 	voucherCountry = appInfo.voucherCountryList.find((item:any)=>{
    // 		return item.name === exp.name
    // 	})
    // }
    let filterPerksParams = { url: this.custom_url };
    let filterVoucherParams = { url: this.custom_url, filter_group_id: "41" };
    //reset country list
    // setExperienceCountryList([]);
    setVoucherCountryList([]);
    // setPerksCountryList([])
    if (!isLoggedIn && customUrl === "/") {
      //	delete filterPerksParams.url;
      //	delete filterVoucherParams.url;
    }
    const getCountryIsoCode2 = ApiService.getCountryIsoCode2();
    const getCategoryList = ApiService.getUrlBasedVoucher(
      this.custom_url,
      (voucherCountry && voucherCountry.country_id) || "",
      isLoggedIn
    );
    let promiseArray = [
      Promise.resolve(null),
      Promise.resolve(null),
      Promise.resolve(null),
      getCountryIsoCode2,
      getCategoryList,
    ];
    if (routeList.includes(ROUTE_NAME.EXPERIENCES)) {
      // promiseArray[0] = ApiService.getCountries(this.custom_url, isLoggedIn);
      promiseArray[2] = ApiService.getExperiencesCategoriesList(iso_code_2);
    }
    if (routeList.includes(ROUTE_NAME.VOUCHERS)) {
      promiseArray[1] = ApiService.fetchVoucherCountries(
        { filtersParams: JSON.stringify(filterVoucherParams) },
        isLoggedIn
      );
    }
    // if (routeList.includes(ROUTE_NAME.PERKES)) { promiseArray[2] = ApiService.fetchPerksCountries({ perks_countries: JSON.stringify(filterPerksParams) }, isLoggedIn) }
    Promise.all(promiseArray)
      .then((countryPromiseResponse) => {
        // const experienceResonse = countryPromiseResponse[0];
        const voucherResponse = countryPromiseResponse[1];
        const experienceCatList = countryPromiseResponse[2];
        const countryOrigindata = countryPromiseResponse[3];
        const categoryListData = countryPromiseResponse[4];
        let defaultCountrylist = [];
        //Experience Country data
        // if (
        //   routeList.includes(ROUTE_NAME.EXPERIENCES) === true &&
        //   experienceResonse &&
        //   experienceResonse.getCountries &&
        //   experienceResonse.getCountries.output
        // ) {
        //   const parsedExperieceResponse = JSON.parse(
        //     experienceResonse.getCountries.output
        //   );
        //   if (parsedExperieceResponse && parsedExperieceResponse.success) {
        //     defaultCountrylist = parsedExperieceResponse.data;
        //     setExperienceCountryList([]);
        //     setDefaultCountryList(defaultCountrylist);
        //   }
        // }

        if (
          experienceCatList &&
          experienceCatList.getExperiencesCategoriesList &&
          experienceCatList.getExperiencesCategoriesList.categories
        ) {
          const newInitialCategories =
            experienceCatList.getExperiencesCategoriesList.categories.map(
              (category: string) => _startCase(category?.toLowerCase())
            );
          setExperienceCategoryList(newInitialCategories || []);
        } else {
          setExperienceCategoryList([]);
        }

        //Voucher Country data
        if (
          routeList.includes(ROUTE_NAME.VOUCHERS) === true &&
          voucherResponse &&
          voucherResponse.data &&
          voucherResponse.data.fetchVouchersFilters &&
          voucherResponse.data.fetchVouchersFilters.Error == false
        ) {
          this.setVoucherCountryData(
            voucherResponse.data.fetchVouchersFilters.filters
          );
          if (
            !routeList.includes(ROUTE_NAME.EXPERIENCES) ||
            !window.location.pathname
              .toString()
              .includes(ROUTE_NAME.EXPERIENCES)
          ) {
            defaultCountrylist =
              voucherResponse.data.fetchVouchersFilters.filters;
            setDefaultCountryList(defaultCountrylist);
          }
        }
        //Perks Country data
        // if (routeList.includes(ROUTE_NAME.PERKES) === true &&  perksResponse && perksResponse.perksCountries && perksResponse.perksCountries.output) {
        // 	const response = JSON.parse(perksResponse.perksCountries.output);
        // 	this.setPerksCountryData(response.data);
        // 	if(!routeList.includes(ROUTE_NAME.EXPERIENCES) && !routeList.includes(ROUTE_NAME.VOUCHERS)) {
        // 		defaultCountrylist = response.data;
        // 		setDefaultCountryList(defaultCountrylist)
        // 	}
        // }
        //store url based voucher category in redux
        if (
          categoryListData &&
          categoryListData.data &&
          categoryListData.data.fetchVouchersFilters &&
          categoryListData.data.fetchVouchersFilters.filters
        ) {
          let output = categoryListData.data.fetchVouchersFilters.filters;
          setVoucherCategoryList(output);
        }
        // if (
        //   experienceCategoryListData &&
        //   experienceCategoryListData.data &&
        //   experienceCategoryListData.data.getUrlBasedCategories
        // ) {
        //   const resData = JSON.parse(
        //     experienceCategoryListData.data.getUrlBasedCategories.output
        //   ).data;
        //   setExperienceCategoryList(resData);
        // }
        //set default country as per the origin from api
        this.setUserCountryFromIp(countryOrigindata, defaultCountrylist);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  componentDidMount() {
    const sap_wallet_id = new URLSearchParams(window.location.search).get(
      "sap_wallet_id"
    );
    if (sap_wallet_id) {
      sessionStorage.setItem("sap_wallet_id", sap_wallet_id);
    }
    this.getCustomUrl();
    this.doLoginCheck();
    this.doAppVersionCheck();
    //this.getCategoryList();
    let token = getCookieToken(LOGIN_COOKIE_NAME);
    let authToken = getCookieToken("a_t") || "";
    setGlobalCookie("pltfm", "4");
    setInterval(() => {
      this.doAppVersionCheck();
      token = getCookieToken(LOGIN_COOKIE_NAME);
      if (Number(token) !== 1 && this.props.isLoggedIn) {
        this.props.resetCart();
        this.props.resetUserData();

        const { custom_url = "/" } = this.props.branding;
        window.location.replace(custom_url + window.location.search);
      }
      if (Number(token) === 1 && !this.props.isLoggedIn) {
        this.doLoginCheck();
        // const { custom_url = '/' } = this.props.branding;
        // window.location.replace(custom_url);
      }
    }, 5000);
    setTimeout(() => {
      if (Number(token) === 1 && this.props.isLoggedIn) {
        this.fetchUserDetails();
        this.getMenu();
        this.fetchEmpulsePoints();
        this.getGCB();
        this.getXoxoTokens();
        this.getAccounts();
      }
    }, 500);
    if (this.props.isLoggedIn) {
      let cartJson = {
        count: 0,
        url: "/",
      };
      this.props.fetchCartWithThunk({
        cartfetch: JSON.stringify(cartJson),
      });
    }
    // window.onunload = function () {
    // 	sessionStorage.removeItem('ipCountryCode');
    // }
    // if(!this.props.isLoggedIn) {
    // 	this.props.toggleLinkedAccountsModal({ showType: false, fromTab: 'login' });
    // }
  }
  componentDidUpdate(_prevProps: AppProps, _prevState: AppState) {
    this.doLoginCheck();
  }
  componentWillUnmount() {
    window.onbeforeunload = function () {
      sessionStorage.removeItem("ipCountryCode");
      sessionStorage.removeItem("userCountryCode");
    }.bind(this);
  }
  getCategoryList = () => {
    const { isLoggedIn = false, userCountry = "" }: any = this.props;
    const { custom_url = "/" } = this.props.branding;

    const data = ApiService.getUrlBasedVoucher(
      custom_url,
      userCountry,
      isLoggedIn
    );
    data.then((data) => {
      if (
        data.data &&
        data.data.fetchVouchersFilters &&
        data.data.fetchVouchersFilters.filters
      ) {
        let output = data.data.fetchVouchersFilters.filters;
        this.props.setVoucherCategoryList(output);
        // this.props.setExperienceCategoryList(output);
      }
    });
  };
  getPlatformSetting = (branding: any) => {
    // let data ={	urlPrefix: ''}
    let data = { urlPrefix: branding.custom_url };
    const root = document.documentElement;
    ApiService.getPlatformSetting(data, this.props.isLoggedIn)
      .then((response: any) => {
        let platformSetting =
          response && response.data && response.data.fetchPlatformSetting;
        if (platformSetting.success === 1 && platformSetting.data) {
          let primaryLogo =
            platformSetting.data && platformSetting.data.primaryLogo;
          if (primaryLogo !== "") {
            branding.logo_url = primaryLogo;
            this.props.setBranding(branding);
            this.setState({ logo_url: primaryLogo });
            STORAGE("logo_url", primaryLogo);
          }
          let urlPrefix =
            platformSetting &&
            platformSetting.data &&
            platformSetting.data.urlPrefix;
          let primaryColor =
            platformSetting.data && platformSetting.data.primaryColor;

          if (primaryColor && urlPrefix === "") {
            this.updatePrimaryColor(primaryColor);
          } else if (primaryColor && urlPrefix && urlPrefix !== theme_prefix) {
            this.updatePrimaryColor(primaryColor);
          }
          // primaryColor && urlPrefix && (urlPrefix !== theme_prefix) && this.updatePrimaryColor(primaryColor)

          root.style.setProperty("--primary", primaryColor);
          this.props.setPlatformSettings(platformSetting.data);
        } else {
          root.style.setProperty("--primary", "#ee98ab");
          this.props.setPlatformSettings(DEFAULT_PLATFORM_SETTINGS);
        }
      })
      .catch((err) => {
        console.log("getMenu errors", err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updatePrimaryColor = (primary: string) => {
    var updateStyles = document.createElement("style");
    updateStyles.innerHTML = `.headerActions {border-bottom: 2px solid ${primary} }`;
    document.body.appendChild(updateStyles);
  };
  getAccounts = () => {
    ApiService.handleFetchAccounts()
      .then((data: any) => {
        if (data) {
          this.props.setLinkedAccounts(data);
        }
      })
      .catch((err) => console.log(err));
  };
  initializeWeglotApi = () => {
    const headEl = document.getElementsByTagName("head")[0];
    const scriptTag = document.createElement("script");
    scriptTag.innerHTML = `Weglot.initialize({
      api_key: "wg_fae9f07765496a53dbbaf34855be5d376",
      switchers: [
        {
          // Same as button_style at root
          button_style: {
            full_name: true,
            with_name: true,
            is_dropdown: true,
            with_flags: true,
            flag_type: "circle",
            invert_flags: false,
          },
          // Move switcher somewhere in the page
          location: {
            target: ".lngSwitch",
            sibling: null,
          },
        },
      ],
      hide_switcher: true,
    });`;
    headEl.appendChild(scriptTag);
  };
  getMenu = () => {
    if (this.props.isLoggedIn) {
      this.props.setUserJourneyState("points");
      ApiService.getMenu()
        .then((data: any) => {
          if (
            data &&
            data.data &&
            data.data.getMenu &&
            data.data.getMenu.data &&
            data.data.getMenu.data.companyDetails
          ) {
            this.props.setUserCompanyDetails(
              data.data.getMenu.data.companyDetails
            );
          }
          const showMultiLanguage =
            data &&
            data.data &&
            data.data.getMenu &&
            data.data.getMenu.data &&
            data.data.getMenu.data.showMultiLanguage;
          if (showMultiLanguage) {
            this.initializeWeglotApi();
          }
          this.props.setShowMultiLanguage(showMultiLanguage);
          if (
            data &&
            data.data &&
            data.data.getMenu &&
            data.data.getMenu.data &&
            data.data.getMenu.data.showAlternateEmail
          ) {
            this.props.setShowAlternateEmail(
              data.data.getMenu.data.showAlternateEmail
            );
          } else {
            this.props.setShowAlternateEmail(false);
          }
          if (
            data &&
            data.data &&
            data.data.getMenu &&
            data.data.getMenu.data &&
            data.data.getMenu.data.disablePhoneNumberInCheckout
          ) {
            this.props.setPhnNumDisableData(
              data.data.getMenu.data.disablePhoneNumberInCheckout
            );
          } else {
            this.props.setPhnNumDisableData(false);
          }
          if (
            data &&
            data.data &&
            data.data.getMenu &&
            data.data.getMenu.success === 1
          ) {
            this.props.setIsAdmin(true);
          } else {
            this.props.setIsAdmin(false);
          }
        })
        .catch((err) => {
          console.log("getMenu error", err);
          //throw new Error('Higher-level error. ' + err.message);
        });
    }
  };
  doAppVersionCheck = () => {
    if (STORAGE("version") !== version) {
      STORAGE("version", version);
      window.location.reload();
    }
    /**
     * Make api call to version check endpoint
     * success response will have -> "update_type": "hard/soft", "update_required": "true/false"
     * if update_required:
     * 	show app update modal with update required text.
     *  if update type is soft then user should be allowed to dismiss modal. for hard update usuer can not dismss modal
     *  once user clicks update do a hard refresh of the page
     * 
     * How to hard refresh?
     *  
     * -> try location.reload(true); if it works great we are done
     *  else:
     * -> show some loader
     * - do this: caches &&
            caches.keys().then(function(cacheNames) {
              return Promise.all(
                cacheNames.map(function(cacheName) {
                  console.log("deleting cache", cacheName);
                  return caches.delete(cacheName);
                })
              );
            });
        })
    .catch(err => console.dir(err));
  	
    After last step is success. hide loader and do location.reload(true);
     * 
     */
  };
  setDefaultUrl = () => {
    const { branding: { custom_url = "" } = {} }: any = this.props;

    const urlArray = window.location.pathname.toLowerCase().split("/");
    const defaultURL = !(custom_url === "" || custom_url === "/")
      ? custom_url
      : "/marketplace";
    const internalRoute = PLUM_ROUTES.includes(urlArray[1])
      ? PLUM_ROUTES.find((element: any) => element === urlArray[1])
      : "";

    if (window.location.pathname.includes("/p/")) {
      const url: string = window.location.pathname
        .toLowerCase()
        .replace("/p/", "/");
      window.location.replace(url + window.location.search);
    } else if (
      internalRoute !== "" &&
      window.location.pathname.includes("/" + internalRoute) &&
      !window.location.pathname.includes(defaultURL)
    ) {
      const url =
        window.location.pathname
          .toLowerCase()
          .replace("/" + internalRoute, `${defaultURL}/${internalRoute}`) +
        window.location.search;
      //avoid reload for experiences/payment - payu Invoice url
      if (urlArray[1] !== "experiences" && urlArray[2] !== "payment") {
        window.location.replace(url);
      }
    } else if (
      custom_url !== "" &&
      custom_url !== "/" &&
      window.location.pathname.includes("/marketplace")
    ) {
      const url = window.location.href
        .toLowerCase()
        .replace("marketplace", custom_url.split("/")[1]);
      window.location.replace(url + window.location.search);
    } else if (
      window.location.pathname === "/" ||
      PLUM_ROUTES.includes(urlArray[1])
    ) {
      window.location.replace(defaultURL + window.location.search);
    }
  };
  removeP = () => {
    let url_following: any;
    if (window.location.pathname.includes("/p/")) {
      // if old campaign then remove "/p/""
      url_following = window.location.pathname
        .toLowerCase()
        .replace("/p/", "/")
        .split("/");
    } else {
      url_following = window.location.pathname.toLowerCase().split("/"); // split for camapaign url
    }
    return url_following;
  };
  getCustomUrl = () => {
    this.setDefaultUrl();
    let url_following = this.removeP();
    if (url_following[url_following.length - 1] === "") {
      url_following.pop();
    }
    if (url_following && url_following.length > 1) {
      // let url = url_following[1];
      // let elements = url.split("/");
      if (url_following.length >= 2) {
        let custom_route = "/:keyword";
        let current_custom_url = "/";
        let route = "";
        if (
          url_following.length > 2 &&
          (PLUM_ROUTES || []).includes(url_following[2])
        ) {
          current_custom_url = "/" + url_following[1];
          route = url_following[2] || "";
        } else if (url_following.length > 2) {
          custom_route = "/:keyword/:keyword";
          current_custom_url = "/" + url_following[1] + "/" + url_following[2];
          route = url_following[3] || "";
        } else if (
          url_following[1] !==
          ((DEFAULT_PREFIX && DEFAULT_PREFIX.DEFAULT) || "marketplace")
        ) {
          current_custom_url = "/" + url_following[1];
          route = url_following[2] || "";
        } else {
          route = url_following[2] || "";
        }

        this.custom_url = current_custom_url ? current_custom_url : "/";
        // this.setState({
        // 	custom_route: custom_route,
        // 	custom_url: current_custom_url,
        // });
        this.setCustomUrl(custom_route, current_custom_url, route);
      } else {
      }
    } else {
      // 404 page (discuss with Anil  / rediterction on default ?)
    }
  };
  setCustomUrl = (route: any, custom_url: any, appending_url: any) => {
    let endPoint = `/api/${this.isLogin ? "" : "public/"}graph`;
    const data = ApiService.setCustomUrl(custom_url, endPoint);
    this.setState({ isLoading: true });
    const { branding: { custom_url: brandingUrl = "" } = {} }: any = this.props;
    const defaultURL = !(brandingUrl === "" || brandingUrl === "/")
      ? brandingUrl
      : "/marketplace";
    data
      .then((data: any) => {
        if (
          data &&
          data.sitesPreferences &&
          data.sitesPreferences.success === 1
        ) {
          if (appending_url === "vouchers-home") appending_url = "vouchers";
          if (appending_url === "perks-home") appending_url = "perks";
          let branding = {
            logo_url: data.sitesPreferences.brandLogo,
            custom_route: route,
            custom_url:
              custom_url === "/marketplace" || custom_url === "/admin"
                ? "/"
                : custom_url,
          };
          this.setState({
            custom_route: route,
            custom_url: branding.custom_url,
          });

          //plateform preferences settings
          this.getPlatformSetting(branding);
          this.props.setBranding(branding);
          if (data.sitesPreferences.brandLogo) {
            this.setState({ logo_url: data.sitesPreferences.brandLogo });
            STORAGE("logo_url", data.sitesPreferences.brandLogo);
          } else {
            STORAGE("logo_url", "", true);
          }
          this.setRestrictions(function (res: any) {
            if (
              [
                ROUTE_NAME.VOUCHERS,
                ROUTE_NAME.EXPERIENCES,
                ROUTE_NAME.PERKES,
              ].includes(appending_url) &&
              !res.routes.includes(appending_url)
            ) {
              // window.location.replace(custom_url + window.location.search);
            }
          });
        } else {
          this.setRestrictions(function (res: any) {});
          let branding = {
            logo_url: "",
            custom_route: "/:keyword",
            custom_url: "/",
          };
          //plateform preferences settings
          this.getPlatformSetting(branding);
          this.props.setBranding(branding);
          if (!window.location.pathname.includes("marketplace")) {
            //window.location.replace(defaultURL);
          }
        }
      })
      .catch((err) => {
        console.log("setCustomUrl error", err);
        if (!window.location.pathname.includes("marketplace")) {
          //window.location.replace(defaultURL);
        }
        this.setState({ isLoading: false });
      });
  };
  setRestrictions = (restrictions: any) => {
    let payload = { url: this.custom_url, key: "vd" };
    const data = ApiService.getRoutes(payload, this.isLogin);
    data
      .then((data: any) => {
        if (data && data.getRoutes && data.getRoutes.output) {
          let routeList = [
            ROUTE_NAME.EXPERIENCES,
            ROUTE_NAME.VOUCHERS,
            ROUTE_NAME.PERKES,
          ];
          var response = JSON.parse(data.getRoutes.output);
          if (response.success === 1 && response.data && response.data.length) {
            routeList = response.data;
            this.setState({
              routes: routeList,
              check_routes: 1,
              postpaid: response.postpaid,
            });
            restrictions({ routes: routeList, check_routes: 1 });
            this.props.setRoutes(routeList);
          } else {
            this.setState({
              routes: routeList,
              check_routes: 1,
              postpaid: response.postpaid,
            });
            restrictions({ routes: routeList, check_routes: 1 });
          }
          //fetch countries, origin and url based vouchers
          this.fetchAppData(routeList);
        }
      })
      .catch((error) => {
        //fetch countries, origin and url based vouchers
        this.fetchAppData([
          ROUTE_NAME.EXPERIENCES,
          ROUTE_NAME.VOUCHERS,
          ROUTE_NAME.PERKES,
        ]);
        console.log("setRestrictions error", error);
      });
  };
  doLoginCheck = () => {
    let token = getCookieToken(LOGIN_COOKIE_NAME);
    if (Number(token) === 1) {
      this.handleLoggedinUser();
      this.props.setLoginStatus(true);
    } else {
      if (this.props.isLoggedIn) {
        this.props.resetUserData();
        this.props.resetCart();
      }
      // this.props.setLoginStatus(false);
      // this.props.resetUserData();
    }
  };
  handleLoggedinUser = () => {
    if (!this.props.isLoggedIn) {
      this.props.setLoginStatus(true);
    }
    if (!this.props.userDetails || !this.props.userDetails.email) {
      //this.fetchUserDetails();
    }
  };
  fetchEmpulsePoints = () => {
    if (!this.props.isLoggedIn) {
      return;
    }
    ApiService.getUserPoints(1)
      .then((data: any) => {
        const { getPoints } = data;
        if (getPoints.success === 1 && getPoints.data) {
          const { data: pointsData } = getPoints;
          let userPoints = {
            availablePoints:
              (pointsData.RP && pointsData.RP[0] && pointsData.RP[0].balance) ||
              0,
            redeemedPoints:
              (pointsData.RMP &&
                pointsData.RMP[0] &&
                pointsData.RMP[0].balance) ||
              0,
            conversionFactor: pointsData.conversion_factor || 1,
            currencyCode: pointsData.currency_code || "INR",
            platform: pointsData.platform || "",
            currency: pointsData.currency || {},
            integrationName: pointsData.label || "Points",
          };
          this.props.setUserPoints(userPoints);
        }
      })
      .catch((err) => {
        console.log("getUserPoints", err);
      });
  };
  getCustomCampaginName = (domain: string) => {
    const currentUrlArray = window.location.pathname.toLowerCase().split("/");
    if (currentUrlArray[currentUrlArray.length - 1] === "") {
      currentUrlArray.pop();
    }
    if (
      currentUrlArray.length > 2 &&
      domain === currentUrlArray[1] &&
      (PLUM_ROUTES || []).includes(currentUrlArray[2]) === false
    ) {
      return currentUrlArray[2];
    }
    return "";
  };
  fetchUserDetails = () => {
    const {
      setBranding,
      branding: { custom_url = "", logo_url = "" } = {},
    }: any = this.props;
    this.setState({ isFetchingUser: true });
    ApiService.getUserDetails()
      .then((data: any) => {
        // this.setState({ isLoading: false })

        this.props.setUserDetails(data && data.fetchUserDetails);

        let email =
          data && data.fetchUserDetails && data.fetchUserDetails.email
            ? data.fetchUserDetails.email
            : "";
        if (email) {
          setGlobalCookie("LOGIN_EMAIL", btoa(email));
        }
        let domain =
          data && data.fetchUserDetails && data.fetchUserDetails.domain;
        let urlArray = window.location.pathname.toLowerCase().split("/");
        if (domain !== "" && urlArray[1] !== domain.toLowerCase()) {
          const campaignName = this.getCustomCampaginName(domain);
          if (custom_url === "" || custom_url === "/") {
            //set if branding is empty
            let branding = {
              logo_url,
              custom_route: "/:keyword",
              custom_url: domain ? "/" + domain : "/",
            };
            setBranding(branding);
          }
          //avoid reload for experiences/payment - payu Invoice url
          if (urlArray[1] !== "experiences" && urlArray[2] !== "payment") {
            window.location.replace(
              `/${campaignName !== "" ? domain + "/" + campaignName : domain}` +
                window.location.search
            );
          }
        }
      })
      .catch((_err) => {
        this.handleUserDetailsFetchFailure();
      })
      .finally(() => {
        this.setState({ isFetchingUser: false });
      });
  };
  handleUserDetailsFetchFailure = () => {
    deleteCookie(LOGIN_COOKIE_NAME);
    this.props.resetUserData();
    // this.props.setLoginStatus(false);
  };
  toggleLoader = (status: boolean = true) =>
    this.setState({ isLoading: status });
  getGCB = () => {
    if (!this.props.isLoggedIn) {
      return;
    }
    ApiService.getGCB().then((data: any) => {
      if (
        data &&
        data.data &&
        data.data.fetchGcb &&
        data.data.fetchGcb.success &&
        data.data.fetchGcb.success === 1
      ) {
        data = data.data.fetchGcb.data;
        this.props.setGCB({
          availableGCB: data.total || 0,
          voucherList: data.voucher_lists || [],
          currencyCode: data.currency_code || "",
        });
      } else {
        this.props.setGCB({
          availableGCB: 0,
          voucherList: [],
          currencyCode: "",
        });
      }
    });
  };
  getXoxoTokens = () => {
    if (!this.props.isLoggedIn) {
      return;
    }
    ApiService.getXoxoTokens().then((data: any) => {
      if (data) {
        if (!data.total) {
          data.total = 0;
        }
        this.props.setXoxoTokens(data);
      }
    });
  };
  render() {
    return (
      <>
        {this.state.isFetchingUser ? <PageLoader /> : this.props.children}
        {/* {this.props.children} */}
      </>
    );
  }
}
const mapStateToProps = ({ user, appInfo }: StoreState) => ({
  userDetails: getUserDetails(user),
  isLoggedIn: getLoginStatus(user),
  branding: getBranding(appInfo),
  routes: getRoutes(appInfo),
  platFormSettings: getPlatFormSettings(appInfo),
  userPoints: getUserPoints(user),
  countryList: appInfo.countryList,
  userCountry: appInfo.userCountry,
  originCountryCode: appInfo.originCountryCode,
  appInfo,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setLoginStatus: (isLoggedIn: any) => setLoginStatus(isLoggedIn),
      setUserDetails: (userDetails: any) => setUserDetails(userDetails),
      setBranding: (branding: any) => setBranding(branding),
      setRoutes: (routes: any) => setRoutes(routes),
      setUserPoints: (userPoints: Points) => setUserPoints(userPoints),
      setGCB: (gcbObgect: GCB) => setGCB(gcbObgect),
      resetUserData: () => resetUserData(),
      setIsAdmin: (isAdmin: boolean) => setIsAdmin(isAdmin),
      setUserCountry: (countryId: number) => setUserCountry(countryId),
      setUserCountryCode: (countryCode: string) =>
        setUserCountryCode(countryCode),
      setSelectedCountry: (selectedCountry: any) =>
        setSelectedCountry(selectedCountry),
      setOriginCountryCode: (isoCode: any) => setOriginCountryCode(isoCode),
      // setPerksCountryList: (countryList: any) => setPerksCountryList(countryList),
      setExperienceCountryList: (countryList: any) =>
        setExperienceCountryList(countryList),
      setVoucherCountryList: (countryList: any) =>
        setVoucherCountryList(countryList),
      setDefaultCountryList: (countryList: any) => setCountryList(countryList),
      setXoxoTokens: (tokens: Tokens) => setXoxoTokens(tokens),
      setUserCompanyDetails: (company: CompanyDetails) =>
        setUserCompany(company),
      setPlatformSettings: (platformSettings: any) =>
        setPlatformSettings(platformSettings),
      setVoucherCategoryList: (voucherCategoryList: any) =>
        setVoucherCategoryList(voucherCategoryList),
      setExperienceCategoryList: (experienceCategoryList: any) =>
        setExperienceCategoryList(experienceCategoryList),
      fetchCartWithThunk: (variables: any, specialCategory: boolean) =>
        fetchCartWithThunk(variables, (specialCategory = false)),
      resetCart: () => resetCart(),
      setShowAlternateEmail: (showAlternateEmail: boolean) =>
        setShowAlternateEmail(showAlternateEmail),
      setPhnNumDisableData: (disablePhoneNumberInCheckout: boolean) =>
        setPhnNumDisableData(disablePhoneNumberInCheckout),
      setLinkedAccounts: (accountsData: any) => setLinkedAccounts(accountsData),
      toggleLinkedAccountsModal: (data: any) => toggleLinkedAccountsModal(data),
      setShowMultiLanguage: (showMultiLanguage: boolean) =>
        setShowMultiLanguage(showMultiLanguage),
      setUserJourneyState: (userJourneyUseCase: any) =>
        setUserJourneyState(userJourneyUseCase),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Root);
