import React from "react";
import BrandLogo from "../Components/BrandLogo";
import Search from "../Components/Search";
import { Button, Icon } from "@plum-xoxo/stores-dsm";
import "./headerMobile.scss";
import PlumLink from "../../Elements/PlumLink";
import { amountConversion, getColorFromTheme } from "../../../../utils/helpers";

interface Props {
  routes: any;
  branding: any;
  paymentHeader?: boolean;
  noCategory?: boolean;
  isLoggedIn?: Boolean;
  platformSettings?: any;
  user: any;
  Tokens: any;
}
class MobileHeader extends React.Component<Props> {
  state = {
    isOpen: false,
  };

  toggle = (event: React.MouseEvent): void => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  render() {
    const {
      branding,
      isLoggedIn,
      paymentHeader,
      Tokens,
      user: {
        gcb: { availableGCB = 0 } = {},
        userPoints: {
          currency: { pointsBaseConversionValue = 1 } = {},
          availablePoints = 0,
        } = {},
      } = {},
    } = this.props;
    const tokensTotal = Tokens?.total ? Tokens.total : 0;
    const points = availablePoints;
    let xoxoPoint = amountConversion(
      pointsBaseConversionValue || 1,
      availableGCB,
      1
    );
    const finalPoints = tokensTotal ? tokensTotal : points ? points : xoxoPoint;
    return (
      <div className="container-fluid m-sticky-nav box-bottom-shadow pb-0">
        <div
          className="d-flex justify-content-between aling-items-center"
          style={{ padding: "11px 5px" }}
        >
          <div>
            <div className="col-3 text-start p-0 brand">
              <BrandLogo custom_url={branding && branding.custom_url} />
            </div>
          </div>
          <div className="d-flex align-items-center">
            {!paymentHeader ? <Search searchType="DESKTOP" /> : null}
            {isLoggedIn ? (
              <PlumLink url="/points">
                <Button
                  size="small"
                  endIcon={
                    <Icon
                      iconName="XoxoPoints"
                      fill={getColorFromTheme("white", "900")}
                      size={20}
                    />
                  }
                >
                  {finalPoints ? parseFloat(finalPoints).toFixed(2) : 0}
                </Button>
              </PlumLink>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default MobileHeader;
