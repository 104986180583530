import { expOrderDetails, ExpOrderActionTypes, ExperienceItem } from "./types";
import { ActionTypes } from "./actions";
import produce from "immer";

const initialState: expOrderDetails = {
  experiences: [],
};

export const expOrderReducer = (
  state: expOrderDetails = initialState,
  action: ExpOrderActionTypes
): expOrderDetails => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_EXP_ORDER_DETAILS:
      return {
        ...state,
        experiences: updateExperiencesArray(
          (payload as any).expOrderDetails,
          []
        ),
      };

    default:
      return { ...state };
  }
};

const updateExperiencesArray = (
  experienceItem: ExperienceItem,
  existingExperiences: ExperienceItem[] = []
): ExperienceItem[] =>
  produce(existingExperiences, (draft) => {
    const {
      expProductId,
      price,
      currency,
      amount,
      guests,
      data,
      preferredRate,
      preferredTimeSlot,
      preferredDate,
      currency_value,
      convertedAmountValue,
      price_category_id,
    } = experienceItem;
    const dataObj = {
      expProductId,
      price,
      currency,
      amount,
      guests,
      data,
      preferredRate,
      preferredTimeSlot,
      preferredDate,
      currency_value,
      convertedAmountValue,
      price_category_id,
    };
    draft.push(dataObj);
    const localArr = [];
    localArr.push(dataObj);
    localStorage.removeItem("experiences");
    localStorage.setItem("experiences", JSON.stringify(localArr));
    return draft;
  });
