import React, { useState } from "react";
import MediaQuery from "react-responsive";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import CookieBanner from "react-cookie-banner";
import MyCustomCookieBanner from "./MyCustomCookieBanner";
import { StoreState } from "../../../store/store.state";
import {
  getUserPoints,
  getLoginStatus,
  getIsAdmin,
  getTokens,
} from "../../../store/user/getters";
import {
  getRoutes,
  getBranding,
  getVoucherCategoryList,
  getExperienceCategoryList,
  getPlatFormSettings,
  getLinkedAccounts,
} from "../../../store/appInfo/getters";
import { resetUserData, setUserPoints } from "../../../store/user/actions";
import { Points, Tokens } from "../../../store/user/types";
import "../Modal/modal.scss";
import { setOrderType } from "../../../store/order/actions";
import { deleteCookie, STORAGE } from "../../../utils/helpers";
import ApiService from "../../../api/api.service";
import {
  setShowMultiLanguage,
  resetAlternateEmailData,
  setBranding,
  resetMultiAccountsData,
} from "../../../store/appInfo/actions";
import { resetCart } from "../../../store/cart/actions";
import LoaderXs from "../Loader/LoaderXs";
import { getTotalCartItemsCount } from "../../../store/cart/getters";

interface HeaderProps {
  setUserPoints: (points: Points) => void;
  setOrderType?: (orderType: string) => void;
  setShowMultiLanguage?: (show: boolean) => void;
  resetUserData?: () => void;
  resetAlternateEmailData?: () => void;
  resetCart?: () => void;
  resetMultiAccountsData?: () => void;
  setBranding: any;
  userPoints: number;
  user: any;
  routes: any;
  branding?: any;
  paymentHeader?: boolean;
  noCategory?: boolean;
  voucherCategoryList: any;
  experienceCategoryList: any;
  fixedHeader: boolean;
  withoutContainer?: boolean;
  platformSettings?: any;
  isLoggedIn?: Boolean;
  countryList: any;
  isAdmin?: boolean;
  linkedAccounts?: any;
  showMultiLanguage?: Boolean;
  cartItemsCount?: any;
  Tokens?: Tokens;
}
const Header: React.FC<HeaderProps> = ({
  userPoints,
  user,
  voucherCategoryList,
  experienceCategoryList,
  routes,
  branding,
  paymentHeader,
  noCategory,
  fixedHeader,
  withoutContainer = false,
  platformSettings,
  isLoggedIn,
  countryList,
  isAdmin,
  linkedAccounts,
  showMultiLanguage,
  cartItemsCount,
  Tokens,
}) => {
  let conversionFactor =
    user && user.userPoints && user.userPoints.conversionFactor;
  let currencyCode = user && user.userPoints && user.userPoints.currencyCode;
  const adminPanelAccess =
    platformSettings && platformSettings.adminPanelAccess;
  const [logoutLoader, toggleLogoutLoader] = useState(false);

  const clearListCookies = () => {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var spcook = cookies[i].split("=");
      document.cookie = spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";
    }
  };
  const logOut = () => {
    toggleLogoutLoader(true);
    const { custom_url = "" } = branding;
    const data = ApiService.logOut();
    STORAGE("wglang", "", true);
    setShowMultiLanguage(false);
    data.then((data: any) => {
      toggleLogoutLoader(false);
      if (data.success === 1) {
        deleteCookie("LOGIN_EMAIL");
        STORAGE("wglang", "", true);
        setShowMultiLanguage(false);
        resetUserData();
        resetAlternateEmailData();
        resetCart();
        clearListCookies();
        setBranding(branding);
        resetMultiAccountsData();
        setTimeout(() => {
          window.location.replace(
            custom_url?.length ? custom_url + "/" : "/marketplace/"
          );
        }, 500);
      }
    });
  };

  return (
    <>
      <CookieBanner
        dismissOnScroll={false}
        cookie="user-has-accepted-cookies"
        cookiePath="/"
      >
        {(onAccept: any) => <MyCustomCookieBanner onAccept={onAccept} />}
      </CookieBanner>

      <MediaQuery minDeviceWidth={992}>
        <DesktopHeader
          userPoints={userPoints}
          conversionFactor={conversionFactor}
          currencyCode={currencyCode}
          routes={routes}
          paymentHeader={paymentHeader}
          branding={branding ? branding : {}}
          noCategory={noCategory}
          voucherCategoryList={voucherCategoryList}
          experienceCategoryList={experienceCategoryList}
          fixedHeader={fixedHeader}
          withoutContainer={withoutContainer}
          setOrderType={() => setOrderType!("VOUCHERS")}
          countryList={countryList}
          isLoggedIn={isLoggedIn}
          user={user}
          isAdmin={isAdmin}
          adminPanelAccess={adminPanelAccess}
          linkedAccountsCount={linkedAccounts.length}
          showMultiLanguage={showMultiLanguage}
          logOut={logOut}
          cartItemsCount={cartItemsCount}
        />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={991}>
        <MobileHeader
          routes={routes}
          branding={branding ? branding : {}}
          paymentHeader={paymentHeader}
          noCategory={noCategory}
          platformSettings={platformSettings}
          isLoggedIn={isLoggedIn}
          user={user}
          Tokens={Tokens}
        />
      </MediaQuery>
      {logoutLoader ? (
        <LoaderXs msg="Please wait while we sign you off..." />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ user, appInfo, cart }: StoreState) => ({
  userPoints: getUserPoints(user),
  user,
  routes: getRoutes(appInfo),
  isAdmin: getIsAdmin(user),
  voucherCategoryList: getVoucherCategoryList(appInfo),
  experienceCategoryList: getExperienceCategoryList(appInfo),
  branding: getBranding(appInfo),
  platformSettings: getPlatFormSettings(appInfo),
  isLoggedIn: getLoginStatus(user),
  countryList: appInfo.countryList,
  linkedAccounts: getLinkedAccounts(appInfo),
  showMultiLanguage: appInfo.showMultiLanguage,
  cartItemsCount: getTotalCartItemsCount(cart),
  Tokens: getTokens(user),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUserPoints: (userPoints: Points) => setUserPoints(userPoints),
      setOrderType: (orderType: string) => setOrderType(orderType),
      setShowMultiLanguage: (show: boolean) => setShowMultiLanguage(show),
      resetUserData: () => resetUserData(),
      resetAlternateEmailData: () => resetAlternateEmailData(),
      resetCart: () => resetCart(),
      setBranding: (branding: any) => setBranding(branding),
      resetMultiAccountsData: () => resetMultiAccountsData(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
