import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import PlumLink from "../Elements/PlumLink";
import XoxoValidityModal from "../Modal/XoxoValidityModal";
import HomePage from "../Modal/HomePage";
import { StoreState } from "../../../store/store.state";
import { CATEGORY_MENU_ARRAY } from "../../constants/categoryConstant";
import {
  getPlatFormSettings,
  getVoucherCategoryList,
} from "../../../store/appInfo/getters";
import { XoxodayLongLogo } from "../Icons";
import "./footer.scss";
import { ROUTE_PATHS } from "../../constants/routes.constants";
import { Typography, Icon } from "@plum-xoxo/stores-dsm";
import { getColorFromTheme } from "../../../utils/helpers";
interface Props {
  platformSettings?: any;
  voucherCategoryList?: any;
  paymentFooter?: boolean;
}

const Footer: React.FC<Props> = ({
  platformSettings = {},
  voucherCategoryList = {},
  paymentFooter,
}) => {
  const { footer = 1 } = platformSettings;

  const [modal, setModal] = useState(false);
  const [howToRedeem, setHowToRedeem] = useState(false);
  const showModal = () => {
    setModal(!modal);
  };
  const toggle = () => {
    setHowToRedeem(!howToRedeem);
  };

  const renderBottomPart = () => {
    return (
      <div className="container copy-right pt-4">
        <Row className="pb-3 pt-2">
          <Col lg="6" className="text-start ps-0">
            <Row>
              <Col>
                <PlumLink
                  type="ANCHOR_EXT"
                  href="https://www.xoxoday.com/terms-of-use"
                >
                  <Typography
                    className="bottom-navlink"
                    textType="body"
                    size="XS"
                    color={getColorFromTheme("grey", "600")}
                  >
                    Terms of use
                  </Typography>
                </PlumLink>
              </Col>
              <Col>
                <PlumLink
                  type="ANCHOR_EXT"
                  href="https://www.xoxoday.com/privacy-policy"
                >
                  <Typography
                    className="bottom-navlink"
                    textType="body"
                    size="XS"
                  >
                    Privacy Policy
                  </Typography>
                </PlumLink>
              </Col>
              <Col>
                <PlumLink
                  type="ANCHOR_EXT"
                  href="https://www.xoxoday.com/security"
                >
                  <Typography
                    className="bottom-navlink"
                    textType="body"
                    size="XS"
                  >
                    Security
                  </Typography>
                </PlumLink>
              </Col>
              <Col>
                <PlumLink
                  type="ANCHOR_EXT"
                  href="https://www.xoxoday.com/bug-bounty"
                >
                  <Typography
                    className="bottom-navlink"
                    textType="body"
                    size="XS"
                  >
                    Bug Bounty
                  </Typography>
                </PlumLink>
              </Col>
              <Col>
                <PlumLink
                  type="ANCHOR_EXT"
                  href="https://www.xoxoday.com/referral-program"
                >
                  <Typography
                    className="bottom-navlink"
                    textType="body"
                    size="XS"
                  >
                    Referral Program
                  </Typography>
                </PlumLink>
              </Col>
            </Row>
          </Col>
          <Col lg="6" className="text-end pe-0">
            <Typography
              textType="body"
              size="XS"
              color={getColorFromTheme("grey", "600")}
            >
              © Copyright Xoxoday.com
            </Typography>
          </Col>
        </Row>
      </div>
    );
  };

  if (paymentFooter) {
    return (
      <div className="footer container-fluid pt-0">{renderBottomPart()}</div>
    );
  } else {
    const CUSTOMERS_MENU = [
      {
        id: 1,
        title: "Check Xoxo Code Balance",
        onClick: () => showModal(),
        isExternal: false,
      },
      {
        id: 2,
        title: "How to redeem",
        onClick: () => toggle(),
        isExternal: false,
      },
      {
        id: 3,
        title: "Raise a Ticket",
        redirectTo: "https://csgiftxoxo.freshdesk.com/support/tickets/new",
        isExternal: true,
      },
      {
        id: 4,
        title: "FAQs",
        redirectTo:
          "https://xoxoday.gitbook.io/plum/for-end-users/getting-started",
        isExternal: true,
      },
      {
        id: 5,
        title: "Translation Disclaimer",
        redirectTo:
          "https://xoxoday-images.s3.amazonaws.com/plum/pdf/language-translation-dsclaimer-new.pdf",
        isExternal: true,
      },
    ];
    const SOLUTIONS_MENU = [
      {
        id: 1,
        title: "Bulk buy Gift Cards",
        redirectTo: "https://www.xoxoday.com/plum-book-a-demo",
        isExternal: true,
      },
      {
        id: 2,
        title: "Sales Development",
        redirectTo: "https://www.xoxoday.com/plum-solutions/sales-development",
        isExternal: true,
      },
      {
        id: 3,
        title: "Customer Referral Rewards",
        redirectTo:
          "https://www.xoxoday.com/plum-solutions/customer-referral-rewards",
        isExternal: true,
      },
      {
        id: 4,
        title: "Market Research",
        redirectTo: "https://www.xoxoday.com/plum-solutions/market-research",
        isExternal: true,
      },
      {
        id: 5,
        title: "Marketing Campaign",
        redirectTo:
          "https://www.xoxoday.com/plum-solutions/marketing-campaigns",
        isExternal: true,
      },
      {
        id: 6,
        title: "Employee Reward",
        redirectTo: "https://www.xoxoday.com/plum-solutions/employee-rewards",
        isExternal: true,
      },
      {
        id: 7,
        title: "Loyalty Campaign",
        redirectTo: "https://www.xoxoday.com/plum-solutions/loyalty-campaigns",
        isExternal: true,
      },
    ];
    const PLATFORM_MENU = [
      {
        id: 1,
        title: "Features",
        redirectTo: "https://www.xoxoday.com/plum-product/features",
        isExternal: true,
      },
      {
        id: 2,
        title: "Reward Types",
        redirectTo: "https://www.xoxoday.com/plum-product/rewards-types",
        isExternal: true,
      },
      {
        id: 3,
        title: "RaaS API",
        redirectTo: "https://www.xoxoday.com/plum-product/raas-api",
        isExternal: true,
      },
      {
        id: 4,
        title: "Catalog",
        redirectTo: "https://www.xoxoday.com/plum-catalog",
        isExternal: true,
      },
      {
        id: 5,
        title: "Integrations",
        redirectTo: "https://www.xoxoday.com/plum-integrations",
        isExternal: true,
      },
      {
        id: 6,
        title: "Rewards API Catalogue",
        redirectTo: ROUTE_PATHS.REWARDS_API_CATALOGUE,
        isExternal: true,
      },
    ];
    const COMPANY_MENU = [
      {
        id: 1,
        title: "About Plum",
        redirectTo: "https://www.xoxoday.com/plum",
        isExternal: true,
      },
      {
        id: 2,
        title: "Plum Blog",
        redirectTo: "https://blog.xoxoday.com/plum",
        isExternal: true,
      },
      {
        id: 3,
        title: "Suppliers",
        redirectTo: "https://www.xoxoday.com/plum-partners/merchant-partners",
        isExternal: true,
      },
      {
        id: 4,
        title: "Contact sales",
        redirectTo: "https://www.xoxoday.com/plum-book-a-demo",
        isExternal: true,
      },
    ];
    const renderList = (item: any) => {
      if (item.isExternal) {
        return (
          <li key={item.id}>
            <PlumLink type="ANCHOR_EXT" href={item.redirectTo}>
              <Typography className="mb-2" textType="body" size="S">
                {item.title}
              </Typography>
            </PlumLink>
          </li>
        );
      } else {
        return (
          <li key={item.id}>
            <Typography
              onClick={item.onClick}
              className="mb-2"
              textType="body"
              size="S"
            >
              {item.title}
            </Typography>
          </li>
        );
      }
    };
    return (
      <>
        {footer === 0 ? null : (
          <div className="footer container-fluid">
            <Container>
              {modal ? (
                <XoxoValidityModal showmodal={() => showModal()} />
              ) : null}

              {howToRedeem ? (
                <HomePage toggle={toggle} showModal={howToRedeem} />
              ) : null}
              <Row>
                <Col lg="8">
                  <Row>
                    <Col xs="3">
                      <ul>
                        <Typography
                          className="mb-2"
                          fontWeight="medium"
                          size="XS"
                          color={getColorFromTheme("white", "900")}
                        >
                          Customers
                        </Typography>
                        {CUSTOMERS_MENU.map((item: any) => {
                          return renderList(item);
                        })}
                      </ul>
                    </Col>
                    <Col xs="3">
                      <ul>
                        <Typography
                          className="mb-2"
                          fontWeight="medium"
                          size="XS"
                          color={getColorFromTheme("white", "900")}
                        >
                          Solutions
                        </Typography>
                        {SOLUTIONS_MENU.map((item: any) => {
                          return renderList(item);
                        })}
                      </ul>
                    </Col>
                    <Col xs="3">
                      <ul>
                        <Typography
                          className="mb-2"
                          fontWeight="medium"
                          size="XS"
                          color={getColorFromTheme("white", "900")}
                        >
                          Platform
                        </Typography>
                        {PLATFORM_MENU.map((item: any) => {
                          return renderList(item);
                        })}
                      </ul>
                    </Col>
                    <Col xs="3">
                      <ul>
                        <Typography
                          className="mb-2"
                          fontWeight="medium"
                          size="XS"
                          color={getColorFromTheme("white", "900")}
                        >
                          Company
                        </Typography>
                        {COMPANY_MENU.map((item: any) => {
                          return renderList(item);
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col lg="4" className="ps-5">
                  <XoxodayLongLogo width="90px" />
                  <Typography
                    className="my-4"
                    textType="body"
                    size="S"
                    color={getColorFromTheme("grey", "100")}
                  >
                    We simplify reward programs for businesses and make the
                    reward gratification experience delightful for end users.
                  </Typography>
                  <div
                    className="d-flex align-items-center"
                    style={{ columnGap: 12 }}
                  >
                    <PlumLink
                      type="ANCHOR_EXT"
                      href="https://in.linkedin.com/company/xoxoday"
                    >
                      <Icon
                        iconName="LinkedIn"
                        size={20}
                        fill={getColorFromTheme("white", "900")}
                      />
                    </PlumLink>
                    <PlumLink
                      type="ANCHOR_EXT"
                      href="https://www.youtube.com/c/Xoxoday"
                    >
                      <Icon
                        iconName="Youtube"
                        size={20}
                        fill={getColorFromTheme("white", "900")}
                      />
                    </PlumLink>
                    <PlumLink
                      type="ANCHOR_EXT"
                      href="https://twitter.com/thexoxoday"
                    >
                      <Icon
                        iconName="Twitter"
                        size={20}
                        fill={getColorFromTheme("white", "900")}
                      />
                    </PlumLink>
                    <PlumLink
                      type="ANCHOR_EXT"
                      href="https://www.facebook.com/thexoxoday/"
                    >
                      <Icon
                        iconName="Facebook"
                        size={20}
                        fill={getColorFromTheme("white", "900")}
                      />
                    </PlumLink>
                    <PlumLink
                      type="ANCHOR_EXT"
                      href="https://www.instagram.com/thexoxoday/"
                    >
                      <Icon
                        iconName="Instagram"
                        size={20}
                        fill={getColorFromTheme("white", "900")}
                      />
                    </PlumLink>
                  </div>
                </Col>
              </Row>
            </Container>
            {renderBottomPart()}
          </div>
        )}
      </>
    );
  }
};

const mapStateToProps = ({ appInfo }: StoreState) => ({
  platformSettings: getPlatFormSettings(appInfo),
  voucherCategoryList: getVoucherCategoryList(appInfo),
});

export default connect(mapStateToProps, null)(Footer);
