import { CartState, VoucherCartItem } from "./types";
import { isLengthyArray } from "./../../utils/helpers";
import produce from "immer";
const experienceCategoryId = process.env.REACT_APP_GV_EXPERIENCE_CATEGORY_ID;

export const getTotalCartItemsCount = produce(
  (draft: CartState, specialCategory = false, productId = null): any => {
    draft = filterCartDraftVouchers(draft, specialCategory, productId);
    const { entities } = draft;
    let quantity = 0;
    if (isLengthyArray(entities.vouchers)) {
      entities.vouchers.forEach((item) => {
        quantity = quantity + item.quantity;
      });
    }
    return quantity;
  }
);

export const getUniqueItemCount = (state: CartState): number => {
  const { entities: { vouchers = [] } = {} } = state;
  return vouchers.length;
};

export const getVouchers = (state: CartState): VoucherCartItem[] => {
  const { entities } = state;
  return entities.vouchers;
};

export const isRedeemingPoints = (state: CartState): boolean => state.usePoints;

export const isCouponApplied = (state: CartState): boolean =>
  state.couponApplied;

export const getCouponDetails = (state: CartState): any => state.couponDetails;
export const getCartLoading = (state: CartState): any =>
  state.entities.isFetching;
export const getRedirectToCheckout = (state: CartState): any =>
  state.entities.redirectToCheckout;
export const getCartToBaseConversion = (state: CartState): any =>
  state.entities.conversion_to_base;
export const getCartToInrConversion = (state: CartState): any =>
  state.entities.cart_conversion_to_inr;

export const getFilteredCart = produce(
  (draft: CartState, specialCategory = false, productId = null): CartState => {
    draft = filterCartDraftVouchers(draft, specialCategory, productId);
    return draft;
  }
);

export const getFilteredVouchers = produce(
  (draft: CartState, specialCategory = false, productId = null): any => {
    draft = filterCartDraftVouchers(draft, specialCategory, productId);
    return draft.entities.vouchers || [];
  }
);

export const filterCartDraftVouchers = produce(
  (draft: CartState, specialCategory = false, productId = null) => {
    if (draft?.entities?.vouchers?.length) {
      draft.entities.vouchers = draft.entities.vouchers.filter((voucher) => {
        const info = JSON.parse(voucher?.info);
        const categoriesArray = info?.categories
          ? info.categories.split(",")
          : [];
        const isExperienceCateogoryProduct =
          categoriesArray.includes(experienceCategoryId);
        const isProductCheck = !productId
          ? true
          : productId == voucher.productId;
        if (
          (!isExperienceCateogoryProduct && !specialCategory) ||
          (isExperienceCateogoryProduct && specialCategory && isProductCheck)
        )
          return true;
        else return false;
      });
    }
  }
);
