import React from "react";
import Layout from "../../commons/components/Layout/index";
import { Typography, Button, Icon } from "@plum-xoxo/stores-dsm";
import { getColorFromTheme } from "../../utils/helpers";

class PageNotFound extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="text-center pageNotFound"
          style={{ marginTop: 40, marginBottom: 50 }}
        >
          <img
            alt="404 error"
            className=""
            src="https://res.cloudinary.com/dyyjph6kx/image/upload/v1662630113/store/404-not-found.svg"
          />
          <Typography
            className="mb-2"
            fontWeight="semiBold"
            size="S"
            color={getColorFromTheme("grey", "900")}
          >
            Oh no! Error 404
          </Typography>
          <Typography
            className="mb-2"
            textType="body"
            size="L"
            color={getColorFromTheme("grey", "700")}
          >
            We are trying hard to fix it. Shop through other pages.{" "}
          </Typography>
          <div className="mt-4">
            <Button
              onClick={() => window.location.replace("/")}
              startIcon={
                <Icon
                  iconName="ArrowRight"
                  fill={getColorFromTheme("white", "900")}
                  size={20}
                />
              }
            >
              Go to home page
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}
export default PageNotFound;
