import React from "react";

import {
  setGlobalCookie,
  getCookieToken,
  getColorFromTheme,
} from "../../../utils/helpers";
import {
  Modal,
  ModalBody,
  Card,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
  CardText,
} from "reactstrap";
import Loader from "../Loader/index";
import MediaQuery from "react-responsive";
import classnames from "classnames";
import PlumLink from "../Elements/PlumLink";
import "./modal.scss";
import { Typography, Icon, Button } from "@plum-xoxo/stores-dsm";

const REWARDS_REDEMPTION_STEPS = {
  1: [
    {
      title: "Step 1",
      desc: "Add Products into the Cart and proceed to checkout.",
    },
    {
      title: "Step 2",
      desc: "While checkout, enter your gift code and equivalent value will be reduced from your cart total. You may pay partially via other payment modes if the total value exceeds your gift code value.",
    },
  ],
  2: [
    {
      title: "Step 1",
      desc: "Select the products you like and add them to the cart.",
    },
    { title: "Step 2", desc: "Proceed to checkout." },
    {
      title: "Step 3",
      desc: 'In the payments section, select "Use Xoxo Points". If your cart value is more than the value of your points, you can pay using Card/Netbanking. If your cart value is lesser than the value of your points, you can use the remaining value in the next purchase until expiry.',
    },
  ],
};

interface props {
  toggle: Function;
  showModal: boolean;
}

class HomePage extends React.Component<props> {
  state = {
    showModal: true,
    step: 1,
    activeTab: "1",
  };

  componentDidMount() {
    setTimeout(() => {
      setGlobalCookie("RETURNING_USER", true);
    }, 4000);
  }

  toggleTab = (tab: string) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleModal = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
        step: 1,
        activeTab: "1",
      },
      () => {
        this.props.toggle();
      }
    );
  };

  handleCheck = () => {
    this.setState({
      step: 2,
    });
  };

  render() {
    const { step, activeTab } = this.state;
    const { showModal } = this.props;
    return (
      // <MediaQuery minDeviceWidth={992}>
      <Modal
        isOpen={true}
        toggle={this.handleModal}
        className="Home_Modal redeem-modal"
      >
        {/* <ModalHeader toggle={this.handleModal}>Modal title</ModalHeader> */}
        <MediaQuery minDeviceWidth={992}>
          <ModalHeader
            toggle={this.handleModal}
            charCode={
              <Icon
                iconName="Close"
                fill={getColorFromTheme("grey", "900")}
                size={20}
                onClick={() => this.handleModal()}
              />
            }
          >
            <Typography
              className="mb-1"
              textType="body"
              fontWeight="medium"
              size="S"
              color={getColorFromTheme("grey", "900")}
            >
              Xoxo Points & Codes Redemption
            </Typography>
            <Typography
              textType="body"
              size="S"
              color={getColorFromTheme("grey", "600")}
            >
              To redeem your Xoxo Codes or Points, please follow steps below...
            </Typography>
          </ModalHeader>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={992}>
          <ModalHeader>
            <Row>
              <Col xs={2}>
                <Icon
                  iconName="Close"
                  fill={getColorFromTheme("grey", "900")}
                  size={20}
                  onClick={() => this.handleModal()}
                />
              </Col>
              <Col xs={10}>
                <h4 className="mt-2" style={{ fontWeight: "bold" }}>
                  How to Redeem
                </h4>
              </Col>
            </Row>
          </ModalHeader>
        </MediaQuery>
        <ModalBody>
          <div className="bg howToRedeem">
            <Nav tabs className="tnc border-0 mb-2">
              <NavItem>
                <NavLink
                  className={`${classnames({
                    active: activeTab === "1",
                  })} me-5`}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                >
                  <Typography
                    textType="body"
                    fontWeight="medium"
                    size="S"
                    color={
                      activeTab === "1"
                        ? getColorFromTheme("primary", "300")
                        : getColorFromTheme("grey", "600")
                    }
                  >
                    Xoxo Codes Redemption
                  </Typography>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  <Typography
                    textType="body"
                    fontWeight="medium"
                    size="S"
                    color={
                      activeTab === "2"
                        ? getColorFromTheme("primary", "300")
                        : getColorFromTheme("grey", "600")
                    }
                  >
                    Xoxo Points Redemption
                  </Typography>
                  <span className="text-center d-lg-none">Xoxo Points</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {REWARDS_REDEMPTION_STEPS[1].map((item, idx) => {
                  return (
                    <div className="redeemSteps" key={idx}>
                      <Typography
                        textType="body"
                        fontWeight="medium"
                        size="S"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        className="mt-1"
                        textType="body"
                        size="S"
                        color={getColorFromTheme("grey", "600")}
                      >
                        {item.desc}
                      </Typography>
                    </div>
                  );
                })}
              </TabPane>
              <TabPane tabId="2">
                {REWARDS_REDEMPTION_STEPS[2].map((item, idx) => {
                  return (
                    <div className="redeemSteps" key={idx}>
                      <Typography
                        textType="body"
                        fontWeight="medium"
                        size="S"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        className="mt-1"
                        textType="body"
                        size="S"
                        color={getColorFromTheme("grey", "600")}
                      >
                        {item.desc}
                      </Typography>
                    </div>
                  );
                })}
              </TabPane>
            </TabContent>
            <div className="mt-2">
              <PlumLink
                type="ANCHOR_EXT"
                href="https://help.xoxoday.com/plum/user-guide/for-end-users/how-to-redeem"
              >
                <Button
                  size="small"
                  color="text"
                  endIcon={
                    <Icon
                      iconName="ExternalLink"
                      fill={getColorFromTheme("blue", "300")}
                      size={20}
                    />
                  }
                  className="p-0"
                >
                  <Typography
                    textType="body"
                    fontWeight="medium"
                    size="M"
                    color={getColorFromTheme("blue", "300")}
                  >
                    Learn more
                  </Typography>
                </Button>
              </PlumLink>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-block">
          <Typography
            textType="body"
            size="S"
            color={getColorFromTheme("grey", "600")}
          >
            If you have any queries, kindly contact us via Email or Whatsapp
          </Typography>
          <div
            className="d-flex align-items-center mt-3"
            style={{ columnGap: 20 }}
          >
            {[
              { icon: "Mail", text: "cs@xoxoday.com" },
              { icon: "Whatsapp", text: "+91 80 6191 5050" },
            ].map((item) => (
              <div className="d-flex align-items-center">
                <Icon
                  iconName={item.icon}
                  fill={getColorFromTheme("grey", "700")}
                  size={20}
                />
                <Typography className="ms-2" textType="body" size="S">
                  {item.text}
                </Typography>
              </div>
            ))}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default HomePage;
