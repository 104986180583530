import { AppInfoState } from "./types";

import { isLengthyArray } from "../../utils/helpers";

export const getCountryList = (state: AppInfoState): any => state.countryList;

export const getuserCountryId = (state: AppInfoState): number =>
  state.userCountry;

export const getVoucherCategoryList = (state: AppInfoState): any =>
  state.voucherCategoryList;

export const getExperienceCategoryList = (state: AppInfoState): any =>
  state.bokunExperienceCategoryList;

export const getPlatFormSettings = (state: AppInfoState): any =>
  state.platFormSettings;

export const getBranding = (state: AppInfoState): any => state.branding;

export const getLinkedAccounts = (state: AppInfoState): any =>
  state.linkedAccounts;

export const getLinkedAccountsModalCond = (state: AppInfoState): any =>
  state.showLinkedAccountsModal;

export const getRoutes = (state: AppInfoState): any => state.routes;
export const getSearchedKey = (state: AppInfoState): any => state.searchedKey;
export const getSelectedCountry = (state: AppInfoState): any =>
  state.selectedCountry;
export const getCategoryId = (state: AppInfoState): any => state.categoryId;

export const getUserCountryName = (state: AppInfoState): string => {
  if (state.userCountry) {
    return getCountryNameFromId(state.userCountry)(state);
  } else {
    return "";
  }
};

export const getCountryNameFromId =
  (countryId: number) => (state: AppInfoState) => {
    if (isLengthyArray(state.countryList)) {
      const requiredCountry = state.countryList.filter(
        (item: any) => item.country_id === countryId
      );
      return isLengthyArray(requiredCountry) ? requiredCountry[0].name : "";
    } else {
      return "";
    }
  };

export const getUserJourneyUseCase = (state: AppInfoState): any =>
  state.userJourneyUseCase;
