import React, { Component } from "react";
import "./loaders.scss";

interface LoaderProps {
  position?: any;
  minHeight?: any;
  textAlign?: any;
  marginTop?: any;
}

interface LoaderState {}

class Loader extends Component<LoaderProps, LoaderState> {
  render() {
    const styles = {
      position: this.props.position ? this.props.position : "relative",
      minHeight: this.props.minHeight ? this.props.minHeight : "100px",
      textAlign: this.props.textAlign ? this.props.textAlign : "center",
      marginTop: this.props.marginTop ? this.props.marginTop : "20px",
    };

    return (
      <div className="col-lg-12 compLoader" style={styles}>
        <div className="loading">
          <img src="/img/loading.gif" alt="loader" width="30" height="30" />
        </div>
      </div>
    );
  }
}

export const FullPageLoader: React.FC = () => {
  return <div className="fullscreenLoader">Loading&#8230;</div>;
};

export default Loader;
