import { XoxodayLongLogo } from "../../../components/Icons";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../../store/store.state";
import PlumLink from "../../Elements/PlumLink";
import { bindActionCreators, Dispatch } from "redux";
import { setCategoryId } from "../../../../store/appInfo/actions";
interface Props {
  appInfo?: any;
  setCategoryId: Function;
}
class BrandLogo extends React.Component<Props> {
  render() {
    const { appInfo, setCategoryId } = this.props;
    return (
      <PlumLink onClick={() => setCategoryId("")} url="/">
        {appInfo && appInfo.branding && appInfo.branding.logo_url ? (
          <img
            style={{ height: 36 }}
            src={`${appInfo.branding.logo_url}`}
            alt="Logo"
            className="logo"
          />
        ) : (
          <XoxodayLongLogo height="42" width="105" />
        )}
      </PlumLink>
    );
  }
}

const mapStateToProps = ({ appInfo }: StoreState) => ({
  appInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCategoryId: (categoryId: any) => setCategoryId(categoryId),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrandLogo);
