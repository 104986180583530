import { CartState, CartActionTypes, VoucherCartItem } from "./types";
import { ActionTypes } from "./actions";
import produce from "immer";
const experienceCategoryId = process.env.REACT_APP_GV_EXPERIENCE_CATEGORY_ID;

const initialState: CartState = {
  entities: {
    vouchers: [],
    isFetching: false,
    error: null,
    redirectToCheckout: false,
    conversion_to_base: 1,
    cart_conversion_to_inr: 1,
  },
  usePoints: false,
  couponApplied: false,
  couponDetails: null,
};

enum UpdateType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export const cartReducer = (
  state: CartState = initialState,
  action: CartActionTypes
): CartState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ADD_VOUCHER_TO_CART:
      return {
        ...state,
        entities: {
          ...state.entities,
          error: null,
          vouchers: getUpdatedVoucherList(
            (payload as any).voucher,
            state.entities.vouchers,
            UpdateType.ADD
          ),
        },
      };

    case ActionTypes.REMOVE_VOUCHER_FROM_CART:
      return {
        ...state,
        entities: {
          ...state.entities,
          error: null,
          vouchers: getUpdatedVoucherList(
            (payload as any).voucher,
            state.entities.vouchers,
            UpdateType.REMOVE
          ),
        },
      };

    case ActionTypes.UPDATE_VOUCHER_QUANTITY:
      return {
        ...state,
        entities: {
          ...state.entities,
          error: null,
          vouchers: updateVoucherCount(
            (payload as any)?.voucher,
            state.entities?.vouchers
          ),
        },
      };

    case ActionTypes.USE_POINTS:
      return {
        ...state,
        usePoints: payload as boolean,
      };

    case ActionTypes.RESET_COUPON:
      return {
        ...state,
        couponApplied: false,
        couponDetails: null,
      };

    case ActionTypes.SET_COUPON:
      return {
        ...state,
        couponApplied: true,
        couponDetails: (payload as any).couponDetails,
      };

    case ActionTypes.RESET_CART:
      return {
        ...state,
        entities: {
          ...state.entities,
          vouchers: [],
          cart_conversion_to_inr: 1,
          conversion_to_base: 1,
        },
      };

    case ActionTypes.UPDATE_VOUCHER_CART_PRODUCTS:
      return {
        ...state,
        entities: {
          ...state.entities,
          vouchers: (payload as any).newCartVouchers,
        },
      };
    case ActionTypes.FETCHING_CART_DATA:
      return {
        ...state,
        entities: {
          ...state.entities,
          isFetching: (payload as any).status,
          redirectToCheckout: false,
        },
      };
    case ActionTypes.FETCHING_CART_ERROR:
      return {
        ...state,
        entities: {
          ...state.entities,
          isFetching: false,
          error: (payload as any).error,
        },
      };
    case ActionTypes.REDIRECT_TO_CHECKOUT:
      return {
        ...state,
        entities: {
          ...state.entities,
          redirectToCheckout: (payload as any).redirectToCheckout,
        },
      };

    case ActionTypes.CONVERSION_TO_BASE:
      return {
        ...state,
        entities: {
          ...state.entities,
          conversion_to_base: (payload as any).conversion_to_base,
        },
      };

    case ActionTypes.CART_CONVERSION_TO_INR:
      return {
        ...state,
        entities: {
          ...state.entities,
          cart_conversion_to_inr: (payload as any).cart_conversion_to_inr,
        },
      };

    default:
      return state;
  }
};

/**
 * Helper methods
 */
const getUpdatedVoucherList = (
  voucherCartItem: VoucherCartItem,
  existingVouchers: VoucherCartItem[] = [],
  updateType: UpdateType
): VoucherCartItem[] =>
  produce(existingVouchers, (draft) => {
    const { productId, price, quantity, currency, name, info } =
      voucherCartItem;
    const productInfo = JSON.parse(info);
    const categoriesArray = productInfo?.categories
      ? productInfo.categories.split(",")
      : [];
    const isExperienceCateogoryProduct =
      categoriesArray.includes(experienceCategoryId);

    const existingItemIndex = draft.findIndex(
      (item) =>
        item.productId === productId &&
        item.price === price &&
        item.currency === currency
    );

    if (existingItemIndex > -1) {
      if (updateType === UpdateType.ADD) {
        const updatedQuantity = draft[existingItemIndex]["quantity"];
        draft[existingItemIndex].quantity = updatedQuantity;
      }
      // remove
      else {
        draft.splice(existingItemIndex, 1);
      }
    } else {
      if (updateType === UpdateType.ADD) {
        let singleExperienceAndManyNormalGVs = [];

        // if it experience category product remove existing experience gift vouchers
        // and push new experience voucher
        if (isExperienceCateogoryProduct) {
          draft = draft.filter((voucher) => {
            const productInfo = JSON.parse(voucher?.info);
            const categoriesArray = productInfo?.categories
              ? productInfo.categories.split(",")
              : [];
            const shouldProducRemove =
              categoriesArray.includes(experienceCategoryId);
            return !shouldProducRemove;
          });
          draft.push({
            productId,
            price,
            quantity,
            currency,
            name,
            info,
          });
          return draft;
        } else {
          // else just push new voucher
          draft.push({
            productId,
            price,
            quantity,
            currency,
            name,
            info,
          });
          return draft;
        }
      }
    }
  });

const updateVoucherCount = (
  voucherCartItem: VoucherCartItem,
  vouchers: VoucherCartItem[] = []
): VoucherCartItem[] =>
  produce(vouchers, (draft) => {
    const { productId, price, quantity, currency } = voucherCartItem;

    const existingItemIndex = draft?.findIndex(
      (item) =>
        item.productId === productId &&
        item.price === price &&
        item.currency === currency
    );

    if (existingItemIndex > -1) {
      draft[existingItemIndex]["quantity"] = quantity;
    }
  });
