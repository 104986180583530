import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./store/store.reducer";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const middlewares = [reduxThunk];

const migrations = {
  1: (state: any) => {
    return {
      ...state,
      cart: {
        ...state.cart,
        entities: {
          ...state.cart.entities,
          conversion_to_base: 1,
          cart_conversion_to_inr: 1,
        },
      },
    };
  },
};
const persistConfig = {
  key: "root",
  blacklist: ["home", "appInfo.branding", "appInfo.routes", "user.userPoints"],
  storage,
  version: 1, //upgrade this version to add new schema variables to existing presist reducer
  migrate: createMigrate(migrations),
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers =
  process.env.REACT_APP_CUSTOM_NODE_ENV !== "production" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(...middlewares)
)(createStore);

export default () => {
  let store = createStoreWithMiddleware(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};
