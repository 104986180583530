import { OrderState, OrderActionTypes, fieldDetail } from "./types";
import { UserActionTypes } from "../user/types";
import { ActionTypes } from "./actions";

const initialState: OrderState = {
  orderType: "VOUCHERS",
  orderData: {
    checkoutUser: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      phone_code: "",
    },
    paymentType: "",
    selectedExp: {},
  },
  contribution: 0,
  usePoints: false,
  useGCB: false,
  useTokens: false,
  payMethodPrefArray: [],
  appliedVoucherArray: [],
  etihadMemberId: "",
  errorState: {},
};

export const orderReducer = (
  state: OrderState = initialState,
  action: OrderActionTypes | UserActionTypes
): OrderState => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: (payload as any).orderType,
      };

    case ActionTypes.SET_USE_EMPULSE_POINTS:
      return {
        ...state,
        usePoints: (payload as any).usePoints,
        payMethodPrefArray: setPayMethod(
          state,
          "points",
          (payload as any).usePoints
        ),
      };

    case ActionTypes.SET_ORDER_USER_DETAILS:
      return {
        ...state,
        orderData: updateOrderUser(
          state.orderData,
          (payload as any).fieldDetail
        ),
      };

    case ActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        orderData: setOrderUser(state.orderData, (payload as any).userDetails),
      };

    case ActionTypes.UPDATE_SELECTED_EXP:
      return {
        ...state,
        orderData: selectExp(state.orderData, (payload as any).expProduct),
      };

    case ActionTypes.UPDATE_ORDER_STATE:
      return {
        ...initialState,
        orderType: (payload as any).success ? "" : state.orderType,
        orderData: updateOrderUser(
          initialState.orderData,
          state.orderData.checkoutUser
        ),
      };

    case ActionTypes.RESET_USER_DATA:
      return initialState;

    case ActionTypes.SET_USE_GIFT_CARD_BALANCE:
      return {
        ...state,
        useGCB: (payload as any).useGCB,
        payMethodPrefArray: setPayMethod(state, "gcb", (payload as any).useGCB),
      };

    case ActionTypes.SET_USE_XOXO_TOKENS:
      return {
        ...state,
        useTokens: (payload as any).useTokens,
        payMethodPrefArray: setPayMethod(
          state,
          "xoxoToken",
          (payload as any).useTokens
        ),
      };

    case ActionTypes.SET_ETIHAD_MEMBER_ID:
      return {
        ...state,
        etihadMemberId: (payload as any).etihadMemberId,
      };

    case ActionTypes.APPLY_VOUCHER:
      return updateAppliedVouchers("apply", state, (payload as any).voucher);

    case ActionTypes.REMOVE_APPLIED_VOUCHER:
      return updateAppliedVouchers("remove", state, (payload as any).index);

    case ActionTypes.CHECKOUT_MOUNTING:
      return {
        ...state,
        usePoints: false,
        useGCB: false,
        useTokens: false,
        payMethodPrefArray: [],
        appliedVoucherArray: [],
      };

    case ActionTypes.SET_CHECKOUT_ERROR:
      return {
        ...state,
        errorState: (payload as any).errorState,
      };

    default:
      return state;
  }
};

const updateOrderUser = (state: any, fieldDetail: fieldDetail): any => {
  return {
    ...state,
    checkoutUser: fieldDetail,
  };
};

const setOrderUser = (state: any, userDetails: any): any => {
  let data = state.checkoutUser || {};

  if (!userDetails) {
    return state;
  }

  data.firstname = userDetails.first_name || "";
  data.lastname = userDetails.last_name || "";
  data.email = userDetails.email || "";

  let phoneArray =
    userDetails.contact && userDetails.contact.split("-")
      ? userDetails.contact.split("-")
      : [""];
  data.phone = phoneArray[0];
  data.phone_code = "";
  if (phoneArray.length && phoneArray.length > 1) {
    data.phone = phoneArray[1];
    data.phone_code = phoneArray[0];
  }

  return {
    ...state,
    checkoutUser: data,
  };
};

const setPayMethod = (state: OrderState, method: string, use: boolean): any => {
  if (use === true) {
    state.payMethodPrefArray.push(method);
  }
  return state.payMethodPrefArray;
};

const selectExp = (state: any, expProduct: any): any => {
  return {
    ...state,
    selectedExp: expProduct,
  };
};

const updateAppliedVouchers = (
  status: string,
  state: any,
  voucher: any
): any => {
  let newPrefArray: any = state.payMethodPrefArray || [];

  let newVoucherArray: any = state.appliedVoucherArray || [];
  if (status === "apply") {
    newPrefArray.push(newVoucherArray.length);
    newVoucherArray.push(voucher);
  } else {
    // for (let j = 0; j < newVoucherArray.length; j++) {
    if (newVoucherArray.length > voucher) {
      if (newPrefArray.includes(voucher)) {
        let found: boolean = false;
        let selectedKey: any = 0;

        for (let i = 0; i < newPrefArray.length; i++) {
          if (newPrefArray[i] === voucher) {
            found = true;
            selectedKey = i;
          } else if (newPrefArray[i] > voucher) {
            newPrefArray[i] -= 1;
          }
        }
        if (found === true) {
          newPrefArray.splice(selectedKey, 1);
          newVoucherArray.splice(voucher, 1);
        }
      } else {
        newVoucherArray.splice(voucher, 1);
      }
    } else {
      if (newPrefArray.includes(voucher)) {
        for (let i = 0; i < newPrefArray.length; i++) {
          if (newPrefArray[i] === voucher) {
            newPrefArray.splice(i, 1);
          }
        }
      }
    }
  }

  return {
    ...state,
    appliedVoucherArray: newVoucherArray,
    payMethodPrefArray: newPrefArray,
  };
};
