import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BrandLogo from "../Components/BrandLogo";
import { bindActionCreators, Dispatch } from "redux";
import HomePage from "../../Modal/HomePage";
import "./header.scss";
import Search from "../Components/Search";
import { Icon, Typography, Button } from "@plum-xoxo/stores-dsm";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import PlumLink from "../../../../commons/components/Elements/PlumLink";
import XoxoValidityModal from "../../../../commons/components/Modal/XoxoValidityModal";
import LanguageSelector from "../Components/LanguageSelector";
import SelectCountry from "../Components/CountrySelection";
import HeaderMenuDropdown from "../Components/HeaderMenuDropdown";
import { SingleDot } from "../../../../commons/components/Icons";
import { setOrderType } from "../../../../store/order/actions";
import {
  amountConversion,
  capitalizeEachWord,
  getColorFromTheme,
  isLengthyArray,
} from "../../../../utils/helpers";
import { StoreState } from "../../../../store/store.state";
import { getTokens } from "../../../../store/user/getters";
import { Tokens } from "../../../../store/user/types";
import {
  getPlatFormSettings,
  getUserJourneyUseCase,
} from "../../../../store/appInfo/getters";
import { setUserJourneyState } from "../../../../store/appInfo/actions";

interface DesktopHeaderProps {
  userPoints?: number;
  routes: any;
  branding?: any;
  paymentHeader?: boolean;
  voucherCategoryList: object[];
  experienceCategoryList: any;
  fixedHeader: boolean;
  withoutContainer?: boolean;
  setOrderType?: (orderType: string) => void;
  countryList: any;
  isLoggedIn?: Boolean;
  user: any;
  isAdmin?: Boolean;
  adminPanelAccess?: any;
  linkedAccountsCount: number;
  showMultiLanguage?: Boolean;
  logOut: any;
  cartItemsCount?: any;
  Tokens?: Tokens;
  userJourneyUseCase: any;
  setUserJourneyState: any;
  platformSettings?: any;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  routes,
  branding,
  paymentHeader,
  voucherCategoryList,
  experienceCategoryList,
  fixedHeader,
  withoutContainer = false,
  setOrderType,
  isLoggedIn,
  user,
  isAdmin,
  adminPanelAccess,
  linkedAccountsCount,
  showMultiLanguage,
  logOut,
  cartItemsCount,
  Tokens,
  userJourneyUseCase = "",
  setUserJourneyState,
  platformSettings = {},
}) => {
  const [modal, setModal] = useState(false);
  const [showVoucherBalModal, toggleVoucherBalModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTip = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    const redeem = new URLSearchParams(window.location.search).get("redeem");
    if (redeem === "code") {
      setUserJourneyState("code");
      toggleTip();
      setTimeout(() => {
        setTooltipOpen(false);
      }, 4000);
    }
  }, []);

  if (paymentHeader) {
    return (
      <div style={{ position: "relative" }}>
        <div
          className={`${
            fixedHeader ? "fixed-top bg-white" : ""
          } header container-fluid payment-header p-3`}
        >
          <div className={!withoutContainer ? "container" : ""}>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ height: "40px" }}>
                <BrandLogo custom_url={branding.custom_url} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const CartSelector = () => {
    return (
      <PlumLink url="/cart" onClick={() => setOrderType!("VOUCHERS")}>
        <Button color="text">
          <div className="d-flex align-items-center">
            <span className="position-relative">
              {cartItemsCount
                ? SingleDot({ position: "absolute", top: "2px", left: "14px" })
                : null}
              <Icon
                iconName="ShoppingBag"
                size={20}
                fill={getColorFromTheme("grey", "800")}
              />
            </span>
            <span className="ms-2">
              <Typography
                textType="body"
                size="M"
                color={getColorFromTheme("grey", "800")}
              >
                Cart
              </Typography>
            </span>
          </div>
        </Button>
      </PlumLink>
    );
  };

  const HelpSection = () => {
    return (
      <PlumLink type="ANCHOR_EXT" href="https://www.xoxoday.com/support">
        <Button color="text">
          <div className="d-flex align-items-center">
            <Icon
              iconName="InfoCircle"
              size={20}
              fill={getColorFromTheme("grey", "800")}
            />
            <span className="ms-2">
              <Typography
                textType="body"
                size="M"
                color={getColorFromTheme("grey", "800")}
              >
                Help
              </Typography>
            </span>
          </div>
        </Button>
      </PlumLink>
    );
  };

  const LoginSelector = () => {
    return (
      <PlumLink url="/login">
        <Button
          endIcon={
            <Icon
              iconName="MyProfile"
              fill={getColorFromTheme("white", "900")}
            />
          }
        >
          Sign In
        </Button>
      </PlumLink>
    );
  };

  const PointsView = () => {
    const {
      customText: { empulsPoints: empulsPointsText = "Xoxo Points" } = {},
    } = platformSettings;
    const {
      gcb: { availableGCB = 0 } = {},
      userPoints: {
        currency: { pointsBaseConversionValue = 1, code = "INR" } = {},
        availablePoints = 0,
      } = {},
    } = user;
    const tokensTotal = Tokens?.total ? Tokens.total : 0;
    const points = availablePoints;
    const xoxoPoint = amountConversion(
      pointsBaseConversionValue || 1,
      availableGCB,
      1
    );

    const finalPoints = tokensTotal ? tokensTotal : points ? points : xoxoPoint;
    return (
      <>
        <UncontrolledTooltip
          placement="bottom-end"
          target="pointsConversionTooltip"
        >
          <Typography
            textType="body"
            size="S"
            color={getColorFromTheme("white", "900")}
          >
            {!tokensTotal
              ? points
                ? `1 Reward Point = 1 ${code}`
                : `1 ${
                    empulsPointsText ? empulsPointsText : "Xoxopoint"
                  } = 1 ${code}`
              : `1 Reward Point = 1 ${code}`}
          </Typography>
        </UncontrolledTooltip>
        <span>
          <PlumLink url="/points">
            <Button
              id="pointsConversionTooltip"
              endIcon={
                <Icon
                  iconName="XoxoPoints"
                  size={20}
                  fill={getColorFromTheme("white", "900")}
                />
              }
            >
              {finalPoints ? parseFloat(finalPoints).toFixed(2) : 0}
            </Button>
          </PlumLink>
        </span>
      </>
    );
  };

  const ProfileSelector = () => {
    const { userDetails: { user_login_type = "0" } = {} } = user;
    const profileMenuItems = [
      {
        id: 1,
        name: "Profile",
        redirectTo: "/profile",
        iconName: "MyProfile",
        show: true,
      },
      {
        id: 2,
        name: "Orders",
        redirectTo: routes.includes("vouchers")
          ? "/orders"
          : "/experiences/orders",
        iconName: "ReceiptCheck",
        show: true,
      },
      {
        id: 3,
        name: "Xoxo Points",
        redirectTo: "/points",
        iconName: "XoxoPoints",
        show: true,
      },
      {
        id: 4,
        name: "Xoxo Code",
        redirectTo: "/xoxovouchers",
        iconName: "XoxoCode",
        show: true,
      },
      {
        id: 5,
        name: "Switch to Admin",
        redirectTo: "/admin",
        iconName: "ShieldTick",
        show: isAdmin && adminPanelAccess,
      },
      {
        id: 6,
        name: "Switch Account",
        redirectTo: "/switch-account",
        iconName: "SwitchHorizontal",
        show: linkedAccountsCount > 1 && user_login_type !== "2",
      },
    ];
    return (
      <>
        <Button color="text">
          <div className="d-flex align-items-center">
            <Icon iconName="MyProfile" size={20} />
            <span className="ms-2">
              <Typography textType="body" size="M">
                Account
              </Typography>
            </span>
          </div>
        </Button>
        <HeaderMenuDropdown customWidth={242} customClass="py-0">
          <div className="countryPills">
            {profileMenuItems.map((item) => {
              if (item.show) {
                return (
                  <PlumLink
                    type={item.id === 5 ? "ANCHOR_EXT" : null}
                    target={item.id === 5 ? "_blank" : null}
                    href={
                      item.id === 5
                        ? window.location.origin + item.redirectTo
                        : null
                    }
                    url={item.id !== 5 ? item.redirectTo : null}
                  >
                    <div key={item.id} className="cursor-pointer countryPill">
                      <Icon
                        iconName={item.iconName}
                        size={16}
                        fill={getColorFromTheme("grey", "900")}
                      />
                      <Typography
                        textType="body"
                        size="S"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </PlumLink>
                );
              }
              return null;
            })}
            <div
              className="cursor-pointer countryPill"
              onClick={() => logOut()}
            >
              <Icon
                iconName="ArrowCircleBroken"
                size={16}
                fill={getColorFromTheme("grey", "900")}
              />
              <Typography
                textType="body"
                size="S"
                color={getColorFromTheme("grey", "900")}
              >
                Logout
              </Typography>
            </div>
          </div>
        </HeaderMenuDropdown>
      </>
    );
  };

  const paths = window.location?.pathname?.split("/");
  const activeRoute =
    paths[paths.length - 1] === ""
      ? paths[paths.length - 2]
      : paths[paths.length - 1];

  return (
    <div className="fixed-header">
      {modal ? <HomePage toggle={toggle} showModal={modal} /> : null}
      {showVoucherBalModal ? (
        <XoxoValidityModal showmodal={() => toggleVoucherBalModal(false)} />
      ) : null}
      <div className="headerActions">
        <div className="container h-100">
          <div className="d-flex align-items-center justify-content-between">
            <div className="logoContainer">
              <BrandLogo custom_url={branding.custom_url} />
            </div>
            <div className="d-flex justify-content-center position-relative">
              <Search searchType="DESKTOP" />
            </div>
            <div className="action-items">
              <div className="d-flex header-actions-items justify-content-end">
                {showMultiLanguage ? (
                  <div className="menuSelect">
                    <LanguageSelector />
                  </div>
                ) : null}
                <div className="menuSelect">
                  <SelectCountry />
                </div>
                <div className="menuSelect">
                  <HelpSection />
                </div>
                <div className="menuSelect">
                  <CartSelector />
                </div>
                {isLoggedIn ? (
                  <div className="menuSelect">
                    <ProfileSelector />
                  </div>
                ) : null}

                {!userJourneyUseCase ? (
                  <div className="loginBtn ms-3">
                    {!isLoggedIn ? <LoginSelector /> : <PointsView />}
                  </div>
                ) : userJourneyUseCase === "points" ? (
                  <PointsView />
                ) : null}
                {userJourneyUseCase === "code" ? (
                  <div className="menuSelect">
                    <Button
                      variant="outlined"
                      onClick={() => toggleVoucherBalModal(true)}
                    >
                      Check Code Balance
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerNavigation">
        <div className="container h-100">
          <div className="d-flex justify-content-between align-items-center h-100">
            <div>
              {routes.includes("vouchers") && (
                <div className="menuSelect cursor-pointer h-100">
                  {activeRoute === "vouchers" && <div className="activeMenu" />}
                  <PlumLink
                    url={{
                      pathname: "/vouchers",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center px-2 py-3">
                      <Icon
                        iconName="Voucher"
                        fill={
                          activeRoute === "vouchers"
                            ? getColorFromTheme("primary", "300")
                            : getColorFromTheme("grey", "900")
                        }
                        size={20}
                      />
                      <Typography
                        textType="body"
                        fontWeight="medium"
                        size="M"
                        color={
                          activeRoute === "vouchers"
                            ? getColorFromTheme("primary", "300")
                            : getColorFromTheme("grey", "900")
                        }
                      >
                        <span className="ms-2">Gift Card / Merchandise</span>
                      </Typography>
                      <Icon
                        className="ms-2"
                        iconName="ChevronDown"
                        fill={
                          activeRoute === "vouchers"
                            ? getColorFromTheme("primary", "300")
                            : getColorFromTheme("grey", "900")
                        }
                        size={20}
                      />
                    </div>
                  </PlumLink>
                  <HeaderMenuDropdown
                    customWidth={955}
                    customMinWidth={600}
                    customClass="rewardCategories"
                    top={56}
                  >
                    <div className="countryPills">
                      {isLengthyArray(voucherCategoryList) &&
                        voucherCategoryList.map((item: any, idx: number) => (
                          <div
                            key={idx}
                            className="cursor-pointer countryPill px-4"
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            <PlumLink
                              url={{
                                pathname: `/vouchers/?category_id=${item.filter_value_id}`,
                                state: { item },
                              }}
                            >
                              <Typography textType="body" size="S">
                                {capitalizeEachWord(
                                  item.filter_value
                                    .replace("&amp;", "&")
                                    .replace("_", " ")
                                )}
                              </Typography>
                            </PlumLink>
                          </div>
                        ))}
                    </div>
                  </HeaderMenuDropdown>
                </div>
              )}
              {routes.includes("experiences") && (
                <div className="menuSelect cursor-pointer h-100 ms-4">
                  {activeRoute === "experiences" && (
                    <div className="activeMenu" />
                  )}
                  <PlumLink
                    url={{
                      pathname: "/experiences",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center px-2 py-3">
                      <Icon
                        iconName="Experience"
                        fill={
                          activeRoute === "experiences"
                            ? getColorFromTheme("primary", "300")
                            : getColorFromTheme("grey", "900")
                        }
                        size={20}
                      />
                      <Typography
                        textType="body"
                        fontWeight="medium"
                        size="M"
                        color={
                          activeRoute === "experiences"
                            ? getColorFromTheme("primary", "300")
                            : getColorFromTheme("grey", "900")
                        }
                      >
                        <span className="ms-2">Experience </span>
                      </Typography>
                      {isLengthyArray(experienceCategoryList) ? (
                        <Icon
                          className="ms-2"
                          iconName="ChevronDown"
                          fill={
                            activeRoute === "experiences"
                              ? getColorFromTheme("primary", "300")
                              : getColorFromTheme("grey", "900")
                          }
                          size={20}
                        />
                      ) : null}
                    </div>
                  </PlumLink>
                  {isLengthyArray(experienceCategoryList) ? (
                    <HeaderMenuDropdown
                      customWidth={955}
                      customMinWidth={600}
                      customClass="rewardCategories"
                      top={56}
                    >
                      <div className="countryPills">
                        {experienceCategoryList.map(
                          (item: any, idx: number) => {
                            return (
                              <div
                                key={idx}
                                className="cursor-pointer countryPill px-4"
                                onClick={() => {
                                  window.location.reload();
                                }}
                              >
                                <PlumLink
                                  url={{
                                    pathname: `/experiences/?categories[]=${item}`,
                                    state: { item },
                                  }}
                                >
                                  <Typography textType="body" size="S">
                                    {capitalizeEachWord(item || "")}
                                  </Typography>
                                </PlumLink>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </HeaderMenuDropdown>
                  ) : null}
                </div>
              )}
            </div>
            <div className="d-flex align-items-center rightSection">
              <Button
                color="text"
                onClick={() =>
                  window.open("https://www.xoxoday.com/plum-book-a-demo")
                }
              >
                <Typography
                  textType="body"
                  size="S"
                  color={getColorFromTheme("grey", "700")}
                >
                  Bulk Orders
                </Typography>
              </Button>
              <Button color="text" onClick={() => toggle()}>
                <Typography
                  textType="body"
                  size="S"
                  id="redeem-tooltip"
                  color={getColorFromTheme("grey", "700")}
                >
                  How to Redeem
                </Typography>
                <Tooltip
                  isOpen={tooltipOpen}
                  target="redeem-tooltip"
                  toggle={toggleTip}
                  placement="bottom"
                  className="redeem-tooltip"
                >
                  Click here to know How to <br /> Redeem?
                </Tooltip>
              </Button>
              {!userJourneyUseCase ? (
                <Button
                  color="text"
                  onClick={() => toggleVoucherBalModal(true)}
                >
                  <Typography
                    textType="body"
                    size="S"
                    color={getColorFromTheme("grey", "700")}
                  >
                    Check Xoxocode Balance
                  </Typography>
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, appInfo }: StoreState) => ({
  user,
  Tokens: getTokens(user),
  userJourneyUseCase: getUserJourneyUseCase(appInfo),
  platformSettings: getPlatFormSettings(appInfo),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOrderType: (orderType: string) => setOrderType(orderType),
      setUserJourneyState: (userJourneyUseCase: any) =>
        setUserJourneyState(userJourneyUseCase),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader);
