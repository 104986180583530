import React from "react";
import { getColorFromTheme, stripHtml } from "../../../../../../utils/helpers";
import PlumLink from "../../../../Elements/PlumLink/index";
import { Typography } from "@plum-xoxo/stores-dsm";

interface SearchResultsType {
  searchData: object[];
  isSearching: boolean;
  searchkey: any;
  customUrl: any;
  setSearchedKey: any;
  branding: any;
}
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SearchResults: React.FC<SearchResultsType> = ({
  searchData,
  isSearching,
  searchkey,
  customUrl,
  setSearchedKey,
  branding,
}) => {
  // let customUrlNew = customUrl === "/" ? "" : customUrl;

  let customUrls =
    branding && branding.custom_url
      ? branding.custom_url === "/"
        ? ""
        : branding.custom_url
      : "";

  return (
    <div className="py-3 search-mb">
      {isSearching === true ? (
        "Searching.."
      ) : (
        <>
          {searchData.map((item: any, index: number) => {
            return (
              <PlumLink url={{ pathname: item.url, state: {} }}>
                <div
                  className="py-2 px-3 my-1 d-flex justify-content-between"
                  key={index}
                >
                  <Typography
                    textType="body"
                    size="S"
                    style={{ maxWidth: "55vw" }}
                    className="showTwoline"
                  >
                    {item.url.includes("vouchers/")
                      ? stripHtml(item.name)
                      : item.name.replace(/\w\S*/g, function (txt: any) {
                          return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                          );
                        })}
                  </Typography>

                  <div
                    style={{ color: getColorFromTheme("product", "pink300") }}
                  >
                    <Typography textType="body" size="S">
                      {item.type && item.type === "experience"
                        ? "In Experience"
                        : "In GC/Merch"}
                    </Typography>
                  </div>
                </div>
              </PlumLink>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SearchResults;
