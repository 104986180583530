import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ROUTE_PATHS } from "./commons/constants/routes.constants";
import { StoreState } from "./store/store.state";
import { getuserCountryId } from "./store/appInfo/getters";
import { getOrderUserDetails } from "./store/order/getters";
import { updateOrderState } from "./store/order/actions";
import { getLoginStatus, getUserDetails } from "./store/user/getters";
import { resetCart } from "./store/cart/actions";
import { getUrlQueryParams, setCookie } from "./utils/helpers";
import CheckAccessRoute from "./hocs/CheckAccessRoute";
import {
  Home,
  ExpeienceListing,
  VoucherListing,
  PerksListing,
  PaymentCallback,
  PaymentExpCallback,
  PaymentStatus,
  Profile,
  Checkout,
  Search,
  ExperienceDescription,
  VoucherDescription,
  ResetPassword,
  CustomLanding,
  PageLoader,
  PageNotFound,
  PaymentSuccessExternal,
  Cart,
  UnderMaintenace,
  RewardsApiCatalogue,
  Signup,
  Login,
  SwitchAccount,
  UnsubscribeMail,
  MobileMenu,
  MobileProfile,
} from "./commons/constants/loadableImports";

interface RoutesProps {
  branding: any;
  userCountry: number;
  isLoggedIn: boolean;
  appInfo: any;
  checkoutUser: any;
  userDetails?: any;
  updateOrderState: (success: boolean) => void;
  resetCart: () => void;
  history?: any;
  match?: any;
}
interface State {
  custom_url: String;
  custom_route: String;
}
class Routes extends React.Component<RoutesProps, State> {
  state = {
    custom_url: "",
    custom_route: "",
  };

  componentWillMount() {
    if (window.location.pathname === "/sso/") {
      const parsedUrlQuery = getUrlQueryParams();
      if (parsedUrlQuery && parsedUrlQuery.token) {
        setCookie("s_e_t=1; a_t=" + parsedUrlQuery.token);
      }
    }
  }

  componentDidUpdate(_prevProps: RoutesProps, _prevState: State) {
    const { branding } = this.props;
    const { custom_route } = this.state;
    if (
      branding &&
      branding.custom_route &&
      branding.custom_route !== custom_route
    ) {
      this.setState({
        custom_route: branding.custom_route,
        custom_url: branding.custom_url,
      });
    }
  }

  render() {
    const {
      isLoggedIn,
      userCountry,
      branding,
      appInfo,
      userDetails,
      resetCart,
      updateOrderState,
    } = this.props;

    let custom_route = branding.custom_route
      ? branding.custom_route
      : "/:keyword";
    let custom_url = branding.custom_url ? branding.custom_url : "/marketplace";
    let selectedCountryName =
      appInfo && appInfo.selectedCountry
        ? appInfo.selectedCountry.name
        : "India";

    return (
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route
            path={`${custom_route + ROUTE_PATHS.EXPERIENCES_LST}`}
            exact
            render={() => (
              <CheckAccessRoute>
                <ExpeienceListing
                  custom_url={custom_url}
                  userCountry={userCountry}
                  isLoggedIn={isLoggedIn}
                />
              </CheckAccessRoute>
            )}
          />

          <Route
            path={`${ROUTE_PATHS.PAYMENT_PAYU}/:data/`}
            exact
            render={() => <PaymentSuccessExternal />}
          />

          <Route
            path={custom_route + ROUTE_PATHS.EXPERIENCE_DESCRIPTION_PRODUCT}
            exact
            render={() => (
              <ExperienceDescription
                custom_url={custom_url}
                isLoggedIn={isLoggedIn}
                selectedCountryName={selectedCountryName}
              />
            )}
          />

          <Route
            path={`${
              custom_route + ROUTE_PATHS.VOUCHERS_LIST
            }/category_id=:keyword`}
            exact
            render={() => (
              <VoucherListing
                custom_url={custom_url}
                userCountry={userCountry}
                isLoggedIn={isLoggedIn}
                history={this.props.history}
              />
            )}
          />

          <Route
            path={`${custom_route}${ROUTE_PATHS.UNSUBSCRIBE_MAIL}/:mailType`}
            exact
            render={(props) => <UnsubscribeMail {...props} />}
          />

          <Route
            path={`${custom_route}${ROUTE_PATHS.MOBILE_MENU}`}
            exact
            render={(props) => <MobileMenu {...props} />}
          />

          <Route
            path={`${custom_route}${ROUTE_PATHS.MOBILE_PROFILE}`}
            exact
            render={(props) => <MobileProfile {...props} />}
          />

          <Route
            path={`${
              custom_route + ROUTE_PATHS.VOUCHER_DESCRIPTION
            }/:keyword/:keyword`}
            exact
            render={() => (
              <VoucherDescription
                custom_url={custom_url}
                isLoggedIn={isLoggedIn}
                selectedCountryName={selectedCountryName}
              />
            )}
          />

          <Route
            path={custom_route + ROUTE_PATHS.VOUCHERS_LIST}
            exact
            render={() => (
              <CheckAccessRoute>
                <VoucherListing
                  custom_url={custom_url}
                  userCountry={userCountry}
                  isLoggedIn={isLoggedIn}
                  history={this.props.history}
                />
              </CheckAccessRoute>
            )}
          />

          <Route
            exact
            path={custom_route + ROUTE_PATHS.RESET_PASSWORD_RP}
            render={(props) => (
              <ResetPassword {...this.state} {...this.props} {...props} />
            )}
          />

          <Route
            exact
            path={custom_route + ROUTE_PATHS.RESET_PASSWORD}
            render={(props) => (
              <ResetPassword {...this.state} {...this.props} {...props} />
            )}
          />

          <Route
            path={custom_route + ROUTE_PATHS.SEARCH}
            exact
            render={() => <Search searchType="MOBILE" />}
          />

          <Route
            path={custom_route + ROUTE_PATHS.PROFILE}
            exact
            component={Profile}
          />

          <Route
            path={custom_route + ROUTE_PATHS.YOUR_ORDERS}
            component={Profile}
          />

          <Route
            path={custom_route + ROUTE_PATHS.EXPERIENCE_ORDERS}
            component={Profile}
          />

          <Route
            path={custom_route + ROUTE_PATHS.XOXO_POINTS}
            component={Profile}
          />
          <Route
            path={custom_route + ROUTE_PATHS.XOXO_VOUCHERS}
            component={Profile}
          />
          <Route
            path={custom_route + ROUTE_PATHS.CHECKOUT}
            exact
            render={(props) => <Checkout {...props} />}
          />

          <Route
            path={custom_route + ROUTE_PATHS.CART}
            exact
            component={Cart}
          />

          <Route
            path={custom_route + ROUTE_PATHS.PAYMENTCALLBACK}
            exact
            render={() => <PaymentCallback history={this.props.history} />}
          />

          <Route
            path={custom_route + ROUTE_PATHS.PAYMENTEXPCALLBACK}
            exact
            render={() => <PaymentExpCallback history={this.props.history} />}
          />

          <Route
            path={`${custom_route + ROUTE_PATHS.PAYMENTSTATUS}/:type/:id/:key`}
            exact
            render={(props) => (
              <PaymentStatus
                userDetail={userDetails}
                resetCart={resetCart}
                updateOrderState={updateOrderState}
                {...props}
              />
            )}
          />
          {custom_route ? (
            <Route
              exact
              path={`${custom_route}/landing`}
              render={(props) => <CustomLanding {...props} />}
            />
          ) : null}

          <Route
            path={custom_route + ROUTE_PATHS.HOME}
            exact
            render={(props) => <CustomLanding {...props} />}
          />

          <Route path={ROUTE_PATHS.HOME} exact render={() => <PageLoader />} />

          <Route
            path={custom_route + ROUTE_PATHS.UNDER_MAINTAIN}
            render={() => <UnderMaintenace />}
          />

          {/* uncomment this to enable rewards api catalog */}
          <Route
            path={custom_route + ROUTE_PATHS.REWARDS_API_CATALOGUE}
            render={() => <RewardsApiCatalogue />}
          />

          {/* <Route
            exact
            path={`${custom_route}${ROUTE_PATHS.SIGNUP}`}
            render={(props) => <Signup {...props} />}
          /> */}

          <Route
            exact
            path={`${custom_route}${ROUTE_PATHS.LOGIN}`}
            render={(props) => <Login {...props} />}
          />

          <Route
            exact
            path={`${custom_route}${ROUTE_PATHS.SWITCH_ACCOUNT}`}
            render={(props) => <SwitchAccount {...props} />}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    );
  }
}

const mapStateToProps = ({ appInfo, user, order }: StoreState) => ({
  branding: appInfo.branding,
  userCountry: getuserCountryId(appInfo),
  isLoggedIn: getLoginStatus(user),
  checkoutUser: getOrderUserDetails(order),
  userDetails: getUserDetails(user),
  appInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateOrderState: (success: boolean) => updateOrderState(success),
      resetCart: () => resetCart(),
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
