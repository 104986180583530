export const DEFAULT_CURRENCY_CODE = "INR";
export const DEFAULT_COUNTRY_ID = 99;
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_COUNTRY_NAME = "India";
export const DEFAULT_CITY_ID = 1319;
export const DEFAULT_CITY_NAME = "Bangalore";
export const LOGIN_COOKIE_NAME = "s_e_t";
export const SINGLE_PRICE_CART_LIMIT = 50;
export const ADNOC = "ADNOC";
export const GTM_ID = "GTM-TRZ23JQ";
export const GTM_VISITOR_LOG = "visitorLog";
export const GTM_PAYMENT_SUCCESS = "paymentSuccess";
export const DEFAULT_SELECTED_COUNTRY = {
  name: "India",
  country_id: 99,
  iso_code_2: "IN",
  iso_code_3: "IND",
};
export const DEFAULT_BRANDING = {
  logo_url: "",
  custom_route: "/:keyword",
  custom_url: "/",
};
export const DEFAULT_PLATFORM_SETTINGS = {
  urlPrefix: "",
  companyName: "",
  customText: {
    empulsPoints: "Points",
    payNowButton: "Pay Now",
  },
  paymentMethodAccess: {
    empulsePoints: 1,
    gcb: 1,
    tokens: 1,
    voucher: 1,
    payu: 1,
  },
  primaryLogo: "",
  primaryColor: "#ee98ab",
  adminPanelAccess: 1,
};

export const CART_ACTION_TYPE = {
  ADD_CART: "ADD_CART",
  REMOVE_CART: "REMOVE_CART",
  UPDATE_CART: "UPDATE_CART",
  BUY_NOW: "BUY_NOW",
};

export const DEFAULT_LINKED_ACC_MODAL_STATE = {
  showType: false,
  fromTab: "login",
};

export const PLACEHOLDER_IMAGE =
  "https://res.cloudinary.com/dyyjph6kx/image/upload/v1655810481/gift_vouchers/voucher_placeholder.svg";
export const DEFAULT_OTP_TIMER = 60;
export const DEFAULT_INTEGRATION_NAME = "Reward Points";
export const MOBILE_NO_ERROR =
  "Mobile number should be between 7 to 16 digits only";
export const EXPERIENCE_GUEST_UPPER_LIMIT = 12;

export const TURNSTILE_SITE_KEY: string = process.env
  .REACT_APP_TURNSTILE_KEY as string;
