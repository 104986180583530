import { AppInfoState, AppInfoActionTypes } from "./types";
import { ActionTypes } from "./actions";
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_ID,
  DEFAULT_COUNTRY_NAME,
  DEFAULT_CITY_ID,
  DEFAULT_CITY_NAME,
  DEFAULT_SELECTED_COUNTRY,
  DEFAULT_BRANDING,
  DEFAULT_PLATFORM_SETTINGS,
  DEFAULT_LINKED_ACC_MODAL_STATE,
} from "../../commons/constants/app.constants";

const initialState: AppInfoState = {
  userCountry: DEFAULT_COUNTRY_ID,
  userCountryCode: DEFAULT_COUNTRY_CODE,
  defaultCountryName: DEFAULT_COUNTRY_NAME,
  defaultCityId: DEFAULT_CITY_ID,
  defaultCityName: DEFAULT_CITY_NAME,
  selectedCountry: DEFAULT_SELECTED_COUNTRY,
  countryList: [DEFAULT_SELECTED_COUNTRY],
  branding: DEFAULT_BRANDING,
  routes: ["experiences", "vouchers", "perks"],
  searchedKey: "",
  originCountryCode: "",
  voucherCountryList: [],
  experienceCountryList: [],
  categoryId: "",
  platFormSettings: DEFAULT_PLATFORM_SETTINGS,
  voucherCategoryList: "",
  experienceCategoryList: "",
  bokunExperienceCategoryList: [],
  linkedAccounts: [],
  showLinkedAccountsModal: DEFAULT_LINKED_ACC_MODAL_STATE,
  showAlternateEmail: false,
  isPrimaryMailExists: true,
  showMultiLanguage: false,
  disablePhoneNumberInCheckout: false,
  userJourneyUseCase: "",
};

export const appInfoReducer = (
  state: AppInfoState = initialState,
  action: AppInfoActionTypes
): AppInfoState => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: (payload as any).countryList,
      };
    case ActionTypes.SET_CATEGORY_ID:
      return {
        ...state,
        categoryId: (payload as any).categoryId,
      };
    case ActionTypes.SET_PLATFORM_SETTINGS:
      return {
        ...state,
        platFormSettings: (payload as any).platFormSettings,
      };
    case ActionTypes.SET_VOUCHER_CATEGORY_LIST:
      return {
        ...state,
        voucherCategoryList: (payload as any).voucherCategoryList,
      };
    case ActionTypes.SET_EXPERIENCE_CATEGORY_LIST:
      return {
        ...state,
        bokunExperienceCategoryList: (payload as any).experienceCategoryList,
      };

    // case ActionTypes.SET_PERKS_COUNTRY_LIST:
    // 	return {
    // 		...state,
    // 		perksCountryList: (payload as any).perksCountryList,
    // 	};

    case ActionTypes.SET_VOUCHER_COUNTRY_LIST:
      return {
        ...state,
        voucherCountryList: (payload as any).voucherCountryList,
      };

    case ActionTypes.SET_EXPERIENCE_COUNTRY_LIST:
      return {
        ...state,
        experienceCountryList: (payload as any).experienceCountryList,
      };

    case ActionTypes.SET_USER_COUNTRY:
      return {
        ...state,
        userCountry: (payload as any).userCountry,
      };

    case ActionTypes.SET_USER_COUNTRY_CODE:
      return {
        ...state,
        userCountryCode: (payload as any).userCountryCode,
      };

    case ActionTypes.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: (payload as any).selectedCountry,
      };
    case ActionTypes.SET_BRANDING:
      return {
        ...state,
        branding: (payload as any).branding,
      };

    case ActionTypes.SET_ROUTES:
      return {
        ...state,
        routes: (payload as any).routes,
      };

    case ActionTypes.SET_SEARCHED_KEY:
      return {
        ...state,
        searchedKey: (payload as any).searchedKey,
      };

    case ActionTypes.SET_ORIGIN_COUNTRY_CODE:
      return {
        ...state,
        originCountryCode: (payload as any).originCountryCode,
      };
    case ActionTypes.SET_LINKED_ACCOUNTS:
      return {
        ...state,
        linkedAccounts: (payload as any).linkedAccounts,
      };
    case ActionTypes.TOGGLE_LINKED_ACCOUNTS_MODAL:
      return {
        ...state,
        showLinkedAccountsModal: (payload as any).data,
      };
    case ActionTypes.SET_SHOW_ALTERNATE_EMAIL:
      return {
        ...state,
        showAlternateEmail: (payload as any).showAlternateEmail,
      };
    case ActionTypes.SET_EMAIL_STATUS:
      return {
        ...state,
        isPrimaryMailExists: (payload as any).isPrimaryMailExists,
      };
    case ActionTypes.RESET_ALTERNATE_EMAIL_DATA:
      return {
        ...state,
        showAlternateEmail: false,
        isPrimaryMailExists: true,
      };
    case ActionTypes.SET_SHOW_MULTILANGUAGE_COMPANY:
      return {
        ...state,
        showMultiLanguage: (payload as any).showMultiLanguage,
      };
    case ActionTypes.RESET_MULTI_ACCOUNTS_DATA:
      return {
        ...state,
        linkedAccounts: [],
        showLinkedAccountsModal: DEFAULT_LINKED_ACC_MODAL_STATE,
      };
    case ActionTypes.SET_PHN_NUM_DISABLE_DATA:
      return {
        ...state,
        disablePhoneNumberInCheckout: (payload as any)
          .disablePhoneNumberInCheckout,
      };
    case ActionTypes.SET_USER_JOURNEY_STATE:
      return {
        ...state,
        userJourneyUseCase: (payload as any).userJourneyUseCase,
      };
    default:
      return state;
  }
};
