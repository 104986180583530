import React from "react";
import ApiService from "../../../../../api/api.service";
import { DropdownToggle, Col } from "reactstrap";
import { Button, Input } from "@plum-xoxo/stores-dsm";
import { Icon, Typography } from "@plum-xoxo/stores-dsm";
import HeaderMenuDropdown from "../HeaderMenuDropdown";
import { getColorFromTheme } from "../../../../../utils/helpers";
interface Props {
  handleCountrySelection: Function;
  countryData: any;
  countryName: string;
  isLoggedIn: boolean;
  appInfo: any;
  setVoucherCategoryList: any;
  history?: any;
}

interface State {
  activeTab: number;
  isOpen: boolean;
  tabName: string;
  countryList: any;
  searchText: string;
}
class CountryDropdown extends React.Component<Props, State> {
  state = {
    activeTab: 0,
    isOpen: true,
    tabName: "experiences",
    countryList: this.props.countryData,
    searchText: "",
  };

  componentDidMount() {
    this.setState({ countryList: this.props.countryData });
  }

  toggle = () =>
    this.setState({
      isOpen: !this.state.isOpen,
    });

  toggletab = (tab: number, item: string) => {
    if (this.state.activeTab !== tab)
      this.setState({ activeTab: tab, tabName: item });
  };

  selectCountry = (item: any): void => {
    this.props.handleCountrySelection(item);
    this.updateVoucherCategory(item);
    this.searchCountryHandler("");
    sessionStorage.setItem("userCountryCode", item.iso_code_2);
  };

  updateVoucherCategory = (item: any) => {
    let {
      isLoggedIn,
      setVoucherCategoryList,
      appInfo: {
        voucherCountryList = [],
        branding: { custom_url: customUrl = "/" } = {},
      } = {},
    } = this.props;

    let voucherCountry = voucherCountryList.find((x: any) => {
      return x.iso_code_2 === item.iso_code_2;
    });
    const getCategoryList = ApiService.getUrlBasedVoucher(
      customUrl,
      (voucherCountry && voucherCountry.country_id) || 976,
      isLoggedIn
    );

    getCategoryList.then((data) => {
      if (
        data.data &&
        data.data.fetchVouchersFilters &&
        data.data.fetchVouchersFilters.filters
      ) {
        let output = data.data.fetchVouchersFilters.filters;
        setVoucherCategoryList(output);
      }
    });
  };

  searchCountryHandler = (event: any): void => {
    const searchText = event ? event.target.value : "";
    const countryList = this.props.countryData.filter((item: any) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
    this.setState({ countryList, searchText });
  };

  componentDidUpdate(_prevProps: any, _prevState: any) {
    if (this.props.countryData !== _prevProps.countryData) {
      this.setState({ countryList: this.props.countryData });
    }
  }
  render() {
    const { countryName = "" } = this.props;

    const { searchText = "", countryList } = this.state;

    return (
      <>
        <Button color="text">
          <div className="d-flex align-items-center">
            <Icon
              iconName="Country"
              size={20}
              fill={getColorFromTheme("grey", "800")}
            />
            <span className="ms-2">
              <Typography
                textType="body"
                size="M"
                color={getColorFromTheme("grey", "800")}
              >
                {countryName}
              </Typography>
            </span>
          </div>
        </Button>
        <HeaderMenuDropdown customWidth={216} customClass="pt-0">
          <div>
            <div
              className="title d-flex align-items-center"
              style={{ padding: 12 }}
            >
              <Icon iconName="Country" size={20} />
              <span className="ms-2">
                <Typography textType="body" fontWeight="medium" size="M">
                  Change Country
                </Typography>
              </span>
            </div>
            <div className="cursor-pointer py-1 px-2 mx-1">
              <Input
                placeholder="Search Country"
                endIcon={<Icon iconName="Search" size={20} fill="#6A737D" />}
                style={{ width: 162 }}
                onChange={(e: any) => this.searchCountryHandler(e)}
                value={searchText}
              />
            </div>
            <div className="countryPills">
              {countryList.map((item: any, idx: number) => (
                <div
                  className="countryPill cursor-pointer"
                  onClick={() => {
                    this.selectCountry(item);
                  }}
                  key={idx}
                >
                  <Typography textType="body" size="S">
                    {item.name}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </HeaderMenuDropdown>
      </>
    );
  }
}

export default CountryDropdown;
