export const ROUTE_PATHS = {
  HOME: "/",
  COMMON: "/common",
  EXPERIENCES_LST: "/experiences",
  PRODUCT_LIST: "/product-list",
  EXPERIENCE_DESCRIPTION: "/experiences/:description",
  EXPERIENCE_DESCRIPTION_PRODUCT: "/experiences/:description/:productID",
  VOUCHERS_LIST: "/vouchers",
  // PERKS_LIST: "/perks",
  PROFILE: "/profile",
  CHECKOUT: "/:category?/checkout",
  CART: "/cart",
  YOUR_ORDERS: "/orders",
  EXPERIENCE_ORDERS: "/experiences/orders",
  XOXO_POINTS: "/points",
  XOXO_VOUCHERS: "/xoxovouchers",
  VOUCHER_DESCRIPTION: "/vouchers/description",
  SEARCH: "/search",
  PAYMENTCALLBACK: "/payment-callback",
  PAYMENTEXPCALLBACK: "/payment-exp-callback",
  PAYMENTSTATUS: "/payment-status",
  EXP_PAYMENT_STATUS: "/exp-payment-status",
  INSURANCE: "/insurance",
  INSURANCE_DESCRIPTION: "/insurance/:description",
  PAYMENT_PAYU: "/experiences/payment",
  UNDER_MAINTAIN: "/maintenance",
  REWARDS_API_CATALOGUE: "/rewards-api-catalogue",
  LOGIN: "/login",
  SIGNUP: "/signup",
  SWITCH_ACCOUNT: "/switch-account",
  RESET_PASSWORD: "/reset-password/:keyword", // for old reset password links
  RESET_PASSWORD_RP: "/rp/:keyword", // for new reset password flow
  UNSUBSCRIBE_MAIL: "/unsubscribe",
  MOBILE_MENU: "/mobile-menu",
  MOBILE_PROFILE: "/mobile-profile",
  // PAYMENT_PAYU_EXTERNAL:'/experiences/payment/:description',
};

// Pls add new routes to admin repo (src/constants/AppConstant) also for checking store URL while DIY signup
export const PLUM_ROUTES = [
  "common",
  "product-list",
  "experiences",
  "vouchers",
  "profile",
  "checkout",
  "orders",
  "points",
  "xoxovouchers",
  "search",
  "payment-callback",
  "landing",
  "payment-exp-callback",
  "payment-status",
  "admin",
  "reset-password",
  "sso",
  "index.html",
  "insurance",
  "payment",
  "maintenance",
  "rewards-api-catalogue",
  "rp",
  "unsubscribe",
  "mobile-menu",
  "mobile-profile",
];

export const DEFAULT_PREFIX = {
  DEFAULT: "marketplace",
};
