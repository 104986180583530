import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import configureStore from "./store";
import Bootstrap from "./Bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle, theme } from "@plum-xoxo/stores-dsm";
import { ThemeProvider } from "styled-components";
// import * as Sentry from '@sentry/browser';

const { persistor, store } = configureStore();

const App: React.FC = () => (
  <div id="xoxoday-webapp">
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <Bootstrap>
            <Router>
              <Routes />
              <ToastContainer />
            </Router>
          </Bootstrap>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </div>
);
export default App;
