import axios from "axios";
import { API_ROUTES, GRAPHQL_QUERIES } from "./api.constants";
import BaseApi from "./baseApi.service";
import PlumAnalytics from "../utils/analytics/index";

const partner_key = process.env.REACT_APP_PARTNER_KEY;
const php_key = process.env.REACT_APP_API_PHP;
const node_server = process.env.REACT_APP_NODE_SERVER;
const pip_flag = process.env.REACT_APP_PIP_FLAG || 0;
const captchaBaseUrl = process.env.REACT_APP_BASE_URL || 0;

class Api extends BaseApi {
  getExperiences = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getExperiencesOrderDetail = async (offset: Number, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const variables = { limit: 10, offset: offset };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE_ORDER_HISTORY,
      "experience",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getUserPoints = async (variables = {}) => {
    const sap_wallet_id = sessionStorage.getItem("sap_wallet_id");
    const url = this.makeBaseUrl(
      !pip_flag
        ? "/api/graph" + API_ROUTES.POINTS
        : "/api/graph" + API_ROUTES.PIP
    );
    const payloadVariables = sap_wallet_id ? { sap_wallet_id } : {};
    const payload = !pip_flag
      ? { query: GRAPHQL_QUERIES.POINTS, tag: "points" }
      : {
          query: GRAPHQL_QUERIES.PIP_GETPOINTS,
          tag: "plum_tp_integrations",
          variables: payloadVariables,
        };

    const headers = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data.data;
    } catch (error: any) {
      console.log(error.response ? error.response : error);
      // throw error;
    }
  };

  getPerks = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.NEW_PERKS,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  };

  getLandingDetails = async (data: any, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const variables = {
      add_data: JSON.stringify({
        limit: 10,
        url: data,
      }),
    };

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.CUSTOM_LANDING,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  setCustomUrl = async (data = {}, endPoint: string) => {
    //Login handled in endpoint
    // const url = this.makePublicUrl(endPoint + API_ROUTES.STORES);
    const url = this.makeBaseUrl(endPoint);
    const variables = {
      preferences: JSON.stringify({
        url: data,
      }),
    };

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.URL_PREFERENCES,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  getRoutes = async (data = {}, isLoggedIn: Boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const variables = {
      routes_input: JSON.stringify(data),
    };

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_ROUTES,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      //	throw error;
    }
  };

  fetchPerks = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.FETCH_PERKS,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchPerksBrands = async (variables = {}, loginStatus: boolean) => {
    const endPoint = loginStatus
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PERKS_BRAND,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getUrlBasedPerks = async (
    variables = {},
    loginStatus: boolean | Boolean | undefined
  ) => {
    const endPoint = loginStatus
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PERKS_CATEGORY,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getVouchers = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.VOUCHER, "voucher", {
      ...variables,
    });

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getExpProdById = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_EXPERIENCE_BY_ID,
      "experience",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getAvailableDates = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_PRODUCT_AVAILABLE_DATES,
      "experience",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  checkAvailabilityPricing = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.CHECK_AVAILABILITY_PRICING,
      "experience",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  /**
   * Add to cart in databases funciton
   */
  addToCart = async (variables = {}) => {
    const endPoint = API_ROUTES.DEFAULT_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_ADD_TO_CART,
      "voucher",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      //throw error;
    }
  };

  /**
   * fetch cart from databases;
   */
  fetchCart = async (variables = {}) => {
    const endPoint = API_ROUTES.DEFAULT_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_FETCH_CART,
      "voucher",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  validateUserEmail = async (email: any, token: any = "") => {
    const url = this.makeBaseUrl(
      "/api/public" + API_ROUTES.VALIDATE_USER_EMAIL
    );
    const payload = {
      variables: {
        user_input: email,
      },
    };
    if (token) {
      (payload.variables as any)["token"] = token;
    }

    const headers = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  validateLoginOtp = async (otpId: string, otp: string, authToken: string) => {
    const url = this.makeBaseUrl("/api/public" + API_ROUTES.VALIDATE_LOGIN_OTP);
    const payload = {
      variables: {
        otp_id: otpId,
        otp: otp,
      },
    };

    const headers: any = this.getHeaders();
    headers["a_t"] = authToken ? authToken : null;
    try {
      const response = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return response.data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resendLoginOtp = async (otpId: string, authToken: string, token: string) => {
    const url = this.makeBaseUrl("/api/public" + API_ROUTES.RESEND_LOGIN_OTP);
    const payload = {
      variables: {
        otp_id: otpId,
        cf_token: token,
      },
    };

    const headers: any = this.getHeaders();
    headers["a_t"] = authToken ? authToken : null;
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  validateSetupOtp = async (
    otpId: string,
    otp: string,
    userReference: string
  ) => {
    const url = this.makeBaseUrl("/api/public" + API_ROUTES.VALIDATE_SETUP_OTP);
    const payload = {
      variables: {
        otp_id: otpId,
        otp: otp,
        user_reference: userReference,
      },
    };
    const headers: any = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resendSetupOtp = async (
    otpId: string,
    userReference: string,
    token: string
  ) => {
    const url = this.makeBaseUrl("/api/public" + API_ROUTES.RESEND_SETUP_OTP);
    const payload = {
      variables: {
        otp_id: otpId,
        user_reference: userReference,
        cf_token: token,
      },
    };

    const headers: any = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resendVerificationLink = async (
    user_input: string,
    reset_link: string,
    is_admin: number = 0
  ) => {
    const url = this.makeBaseUrl(
      "/api/public" + API_ROUTES.RESEND_VERIFICATION_LINK
    );
    const payload = {
      variables: {
        user_input,
        reset_link,
        is_admin,
      },
    };

    const headers: any = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  handleLoginSubmit = async (password: any, auth_token: string) => {
    const url = this.makeBaseUrl("/api/public" + API_ROUTES.VALIDATE_PASSWORD);
    const payload = {
      variables: {
        password: btoa(password),
      },
    };

    const headers: any = this.getHeaders();
    headers["a_t"] = auth_token ? auth_token : null;
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });

      return data.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  handleFetchAccounts = async () => {
    const url = this.makeBaseUrl(
      API_ROUTES.SWITCH_ACCOUNTS + "/fetch_accounts"
    );
    const payload = {};

    const headers: any = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });

      return data.linkedAccounts;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  validateSwitchAccount = async (authToken: any) => {
    const url = this.makeBaseUrl(
      API_ROUTES.SWITCH_ACCOUNTS + "/validate_if_switch-worked"
    );
    const payload = {};

    const headers: any = this.getHeaders();
    headers["a_t"] = authToken ? authToken : null;
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });

      return data.accounts;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  handleSubmitSelectAccount = async (cid: any) => {
    const url = this.makeBaseUrl(
      API_ROUTES.SWITCH_ACCOUNTS + "/select_account"
    );
    const payload = { cid };

    const headers: any = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  getUserDetails = async () => {
    const url = this.makeBaseUrl(
      !pip_flag
        ? "/api/graph" + API_ROUTES.CONSUMER
        : "/api/graph" + API_ROUTES.PIP
    );
    const payload = !pip_flag
      ? this.getGraphqlPayload(GRAPHQL_QUERIES.USER_DETAILS, "consumer", "")
      : this.getGraphqlPayload(
          GRAPHQL_QUERIES.PIP_GETUSERS,
          "plum_tp_integrations",
          ""
        );
    const headers = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });

      let response =
        data.data && data.data.fetchUserDetails
          ? data.data.fetchUserDetails
          : "";
      // if (response.success&&response.success ===1 ){
      // 	PlumAnalytics.login(response);
      // }
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  reCaptcha = async (value: string) => {
    // const url = node_server+'/re-captcha?value='+value
    const url = captchaBaseUrl + "re-captcha?value=" + value;
    try {
      const { data } = await axios(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  reCaptchaV3 = async (value: string) => {
    // const url = node_server+'/re-captcha?value='+value
    const url = captchaBaseUrl + "re-captcha-v3?token=" + value;

    try {
      const { data } = await axios(url, {
        method: "post",
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  forgotPassword = async (data: any) => {
    const url = this.makeBaseUrl("/api/public" + API_ROUTES.RESET_PASSWORD);
    let headers: any = this.getHeaders();
    headers["a_t"] = data.authToken;
    try {
      const { data } = await axios(url, {
        method: "post",
        withCredentials: true,
        headers: headers,
      });

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getCountries = async (
    customUrl: string = "/",
    isLoggedIn: boolean = false
  ) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const variables = {
      input_countries: JSON.stringify({ url: customUrl, key: partner_key }),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.COUNTRY_LIST,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      // throw error;
    }
  };

  fetchPerksCountries = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_PERKS_COUNTRIES,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      //throw error;
    }
  };

  getPlatformSetting = async (data = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const variables = {
      add_data: data,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.FETCH_PLATFORM_SETTING,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      //throw error;
    }
  };

  getAdvBanners = async () => {
    const url = this.makeUrl(API_ROUTES.STORES);
    const add_data = "";
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.BANNER_MODULE,
      "stores",
      {
        add_data,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error: any) {
      console.log(error?.response);
      // throw error;
    }
  };

  fetchVoucherCountries = async (variables = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_CATEGORY,
      "voucher",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  logOut = async () => {
    const url = this.makeBaseUrl(API_ROUTES.LOG_OUT);
    const payload = {};
    const headers = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers,
      });

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getUrlBasedExpCategories = async (customUrl: string = "/") => {
    const url = this.makeUrl(API_ROUTES.STORES);
    const variables = {
      input_cats: JSON.stringify({ url: customUrl, key: partner_key }),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE_CATEGORY,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error: any) {
      console.log(error?.response);
      //throw error;
    }
  };

  getProduct = async (variables = {}) => {
    const url = this.makeUrl(API_ROUTES.STORES);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PRODUCT_DATA,
      "stores",
      {
        ...variables,
      }
    );
    const headers = this.getHeaders();
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error: any) {
      console.log(error?.response);
      // throw error;
    }
  };

  /**
   * @description : Get search items
   * @param {object} variables
   * @param {string} endPoint
   * @return {object}
   */
  getSearchItems = async (variables: object, isLoggedIn: boolean) => {
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_SEARCH_ITEMS,
      "stores",
      {
        ...variables,
      }
    );
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;

    const url = this.makeBaseUrl(endPoint);
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data ? data.data.getData : {};
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getUrlBasedVoucher = async (
    customUrl: string = "/",
    selectedCountry: any = "",
    isLoggedIn: Boolean
  ) => {
    // const url = this.makeUrl(API_ROUTES.STORES);
    const variables = {
      filtersParams: JSON.stringify({
        filter_group_id: "37",
        country_id: selectedCountry,
        url: customUrl,
      }),
    };
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_CATEGORY,
      "voucher",
      {
        ...variables,
      }
    );

    // const headers = this.getHeaders();

    try {
      const { data } = await this.makeAuthRequest(payload, url);

      // const { data } = await axios.post(url, payload, { headers });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchVoucherReport = async (limit: number) => {
    const url = this.makeGraphUrl(API_ROUTES.VOUCHER);
    const variables = {
      egift_params: JSON.stringify({ limit: limit }),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_REPORT,
      "voucher",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchGiftVoucherBalance = async (limit: number) => {
    const url = this.makeGraphUrl(API_ROUTES.VOUCHER);
    const variables = {
      input_params: JSON.stringify({ limit: limit }),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GIFT_VOUCHER_REPORT,
      "voucher",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchOrderHistory = async (offset: number) => {
    const url = this.makeGraphUrl(API_ROUTES.VOUCHER);
    const variables = {
      history_input: JSON.stringify({ limit: 10, offset: offset }),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.ORDER_HISTORY,
      "voucher",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  changePassword = async (data: any) => {
    const url = this.makeGraphUrl(API_ROUTES.CONSUMER);
    const variables = {
      reset_input: data,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.CHANGE_PASSWORD,
      "consumer",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  editProfile = async (data: any) => {
    const url = this.makeGraphUrl(API_ROUTES.STORES);
    const variables = {
      update_data: JSON.stringify(data),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EDIT_PROFILE,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getPerksDetails = async (data: any) => {
    const url = this.makeGraphUrl(API_ROUTES.STORES);
    const variables = {
      add_data: data,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PERKS_DETAILS,
      "stores",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      // throw error
    }
  };

  fetchExperienceTags = async (data: any) => {
    const url = php_key + "?_=" + new Date().getTime();
    const payload = {
      query: data.query,
      city_id: data.city_id,
    };
    const headers = {
      "content-type": "application/x-www-form-urlencoded",
      key: "vd",
      method: "filter/getQueryBasedTags",
      url_type: "new_micro",
    };
    try {
      const { data } = await this.makeRequest(payload, url, "GET", headers);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchCities = async (country_id: any) => {
    const url = this.makeBaseUrl("/api/public/graph");
    const add_data = {
      country_id: country_id,
      key: "vd",
      method: "getLiveCities",
      url_type: "node_partner",
    };
    const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.PLUM_API, "stores", {
      add_data,
    });
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      let output = JSON.parse(
        data.data && data.data.plumApi && data.data.plumApi.output
      );

      return output;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getGCB = async () => {
    const subEndPoint = GRAPHQL_QUERIES.GCB.split(".")[2] || "";
    const url = `${this.makeBaseUrl(
      "/api/graph" + API_ROUTES.STORES
    )}/${subEndPoint}`;
    const payload = {
      query: GRAPHQL_QUERIES.GCB,
      tag: "stores",
    };

    const headers = this.getHeaders();
    try {
      const { data } = await axios(url, {
        method: "post",
        data: payload,
        withCredentials: true,
        headers: headers,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }

    // const url = this.makeGraphUrl(API_ROUTES.STORES);
    // const variables={}
    // const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.GCB, "stores", {
    // 	...variables,
    // });
    // try {
    // 	const { data } = await this.makeAuthRequest(payload,url);
    // 	return data
    // }catch(error){
    // 	console.log(error)
    // 	throw error
    // }
  };

  createVoucherOrder = async (inputData: any, isLoggedIn: boolean = false) => {
    let url: string = this.makeGraphUrl();
    if (isLoggedIn == false) {
      url = this.makeUrl("");
    }
    const variables = {
      voucherOrder: inputData,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_CREATE_ORDER,
      "order",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  updateOrder = async (inputData: any = {}) => {
    // const url = this.makeCheckoutUrl(API_ROUTES.UPDATE_VOUCHER_ORDER);
    // const url = "http://54.148.201.113:8113/payuCallbackVoucher"

    const input = inputData;
    const url = this.makeBaseUrl("/api/public/graph" + API_ROUTES.STORES);

    let headers = {
      method: "payuCallbackVoucher",
      url_type: "checkout",
    };

    let data = {
      input,
      headers,
    };

    let add_data = JSON.stringify(data);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_API_ORDER,
      "stores",
      { add_data }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);

      let output = JSON.parse(
        data.data && data.data.plumApiOrder && data.data.plumApiOrder.output
      );

      return output;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  orderDetail = async (inputData: any = {}) => {
    const input = inputData;
    const url = this.makeBaseUrl("/api/public/graph" + API_ROUTES.STORES);

    let headers = {
      method: "voucherOrderDetails",
      url_type: "checkout",
    };

    let data = {
      input,
      headers,
    };

    let add_data = JSON.stringify(data);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_API_ORDER,
      "stores",
      { add_data }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);

      let output = JSON.parse(
        data.data && data.data.plumApiOrder && data.data.plumApiOrder.output
      );
      return output;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getPaymentMethods = async (inputData: any = {}) => {
    const url = this.makeBaseUrl("/api/public/graph" + API_ROUTES.STORES);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_PAYMENT_METHODS,
      "stores",
      {}
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      let output = JSON.parse(
        data.data &&
          data.data.getPaymentMethods &&
          data.data.getPaymentMethods.output
      );
      return output;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getPayUDetails = async (inputData: any = {}) => {
    const url = this.makeBaseUrl("/api/public/graph" + API_ROUTES.STORES);
    const add_data = {
      ...inputData,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_PAYU_DETAILS,
      "stores",
      { add_data }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      let output = JSON.parse(
        data.data && data.data.getPayUDetails && data.data.getPayUDetails.output
      );
      return output;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  createExpOrder = async (inputData: any, isLoggedIn: boolean = false) => {
    let url: string = this.makeGraphUrl();
    if (isLoggedIn == false) {
      url = this.makeUrl("");
    }
    const variables = {
      expOrder: inputData,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXP_CREATE_ORDER,
      "order",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);

      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  updateExpOrder = async (inputData: any = {}) => {
    const url = php_key + "?_=" + new Date().getTime();

    const payload = inputData;

    const headers = {
      method: "payuCallback",
      url_type: "checkout",
    };

    try {
      const { data } = await this.makeRequest(
        {},
        url,
        "POST",
        headers,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  updateExpNoPayuOrder = async (
    inputData: any = {},
    auth_token: string = ""
  ) => {
    const url = php_key + "?_=" + new Date().getTime();

    const payload = inputData;

    const headers = {
      method: "noPayCheckout",
      url_type: "checkout",
      token: auth_token,
      "Accept-Version": "6.0.0",
    };

    try {
      const { data } = await this.makeRequest(
        {},
        url,
        "POST",
        headers,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  expOrderDetail = async (inputData: any = {}, auth_token: string = "") => {
    const input = inputData;
    const url = this.makeBaseUrl("/api/public/graph" + API_ROUTES.STORES);

    let headers = {
      method: "updateExperienceOrder",
      url_type: "checkout",
    };

    let data = {
      input,
      headers,
    };

    let add_data = JSON.stringify(data);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_API_ORDER,
      "stores",
      { add_data }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);

      let output = JSON.parse(
        data.data && data.data.plumApiOrder && data.data.plumApiOrder.output
      );
      return output;
    } catch (error) {
      console.log(error);
      return {};
    }
    // // const url = this.makeCheckoutUrl(API_ROUTES.EXP_ORDER_DETAIL);
    // const url = php_key + "?_=" + new Date().getTime();
    // // const url = "http://54.148.201.113:8113/payuCallbackVoucher"
    // const payload = inputData;

    // const headers = {
    //   method: "orderDetails",
    //   url_type: "checkout",
    //   token: auth_token,
    //   "Accept-Version": "6.0.0",
    // };

    // try {
    //   const { data } = await this.makeRequest(
    //     {},
    //     url,
    //     "POST",
    //     headers,
    //     payload
    //   );

    //   return data;
    // } catch (error) {
    //   console.log(error);
    //   return {};
    // }
  };

  applyVoucher = async (inputData: any = {}, isLoggedIn = false) => {
    // const url = this.makeCheckoutUrl(API_ROUTES.APPLY_VOUCHER);
    // const url = php_key + "?_=" + new Date().getTime();
    // const url = "http://54.148.201.113:8113/payuCallbackVoucher"

    const input = { ...inputData };

    const url = isLoggedIn
      ? this.makeBaseUrl("/api/graph")
      : this.makeBaseUrl("/api/public/graph");

    let headers = {
      method: "applyVoucher",
      url_type: "checkout",
    };

    let data = {
      input,
      headers,
    };

    let add_data = JSON.stringify(data);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_API_APPLY_VOUCHER,
      "stores",
      { add_data }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);

      let output = JSON.parse(
        data.data && data.data.applyVoucher && data.data.applyVoucher.output
      );
      return output;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  validateResetLink = async (reset_link: any = {}) => {
    const url = this.makeBaseUrl("/api/public/graph");
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VALIDATE_RESET_LINK,
      "stores",
      { reset_link }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  validateIdentity = async (sendData: any = {}) => {
    const url = this.makeBaseUrl("/api/public/graph");
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VALIDATE_IDENTITY,
      "stores",
      { ...sendData }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getUserAssoc = async (sendData: any = {}) => {
    const url = this.makeBaseUrl("/api/public/graph");
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.USER_ASSOC,
      "stores",
      { ...sendData }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getMenu = async () => {
    const url = this.makeGraphUrl(API_ROUTES.STORES);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_MENU,
      "stores",
      {}
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      // throw error
    }
  };

  signUpStores = async (data: any) => {
    const url = this.makeUrl(API_ROUTES.STORES);
    const variables = {
      signup_input: JSON.stringify(data),
    };
    const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.SIGN_UP, "stores", {
      ...variables,
    });
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getCountryIsoCode2 = async () => {
    const url = this.makeBaseUrl("/api/public/graph");

    const add_data = {
      key: "vd",
      method: "files/ipDetails",
      url_type: "node_partner",
    };

    const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.PLUM_API, "stores", {
      add_data,
    });
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      let output = JSON.parse(
        data.data && data.data.plumApi && data.data.plumApi.output
      );
      return output;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resetPassword = async (variables: any) => {
    const url = this.makePublicUrl("/resetPassword");
    variables = JSON.stringify(variables);
    try {
      const { data } = await this.makeAuthRequest(variables, url);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  clienteleFundCheck = async (data: any = {}, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const variables = {
      preferences: JSON.stringify({ ...data, url: "/" }),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.URL_PREFERENCES,
      "stores",
      { ...variables }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);

      if (
        data.data &&
        data.data.sitesPreferences &&
        data.data.sitesPreferences.clientele_flag
      ) {
        return {
          success: 1,
          points_usable: data.data.sitesPreferences.points_usable,
        };
      } else return { success: 0 };
      // return data
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getXoxoTokens = async () => {
    const url = this.makeBaseUrl("/api/graph");
    const variables = {
      user_detail: {
        user_type: "ENDUSER",
      },
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_XOXO_TOKENS,
      "stores",
      { ...variables }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      if (
        data &&
        data.data &&
        data.data.fetchXoxoTokens &&
        data.data.fetchXoxoTokens.success == 1 &&
        data.data.fetchXoxoTokens.data
      ) {
        return data.data.fetchXoxoTokens.data;
      } else {
        return {};
      }
      // return data
    } catch (error) {
      console.log(error);
      throw error;
      return {};
    }
  };
  getCurrency = async (currency_params: any = { currency_code: "INR" }) => {
    // const url = this.makeBaseUrl("/api/public/graph");
    // const variables={
    // 	currencyParams : currency_params
    // }

    // const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.GET_CURRENCY, "stores", {...variables});
    // try {
    // 	const { data } = await this.makeAuthRequest(payload,url);

    // 	if(data && data.data && data.data.getCurrencyDetails && data.data.getCurrencyDetails.Error == false && data.data.getCurrencyDetails.Result && data.data.getCurrencyDetails.Result.length){
    // 		return data.data.getCurrencyDetails.Result[0]
    // 	} else
    // 		return {}
    // 	// return data
    // }catch(error){
    // 	console.log(error)
    // 	throw error
    // 	return {}
    // }
    const url = php_key + "?_=" + new Date().getTime();
    // const url = "http://54.148.201.113:8113/payuCallbackVoucher"
    const payload = currency_params;

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      method: "currency",
      url_type: "currency",
    };

    try {
      const { data } = await this.makeRequest(
        {},
        url,
        "POST",
        headers,
        payload
      );

      let found: any = {};
      if (data && data.Result && data.Result.length) {
        found = data.Result.find(
          (element: any) => element.code === currency_params.currency_code
        );
      }

      if (found && found.code === currency_params.currency_code) {
        return found;
      } else {
        return {};
      }
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  resendVoucher = async (add_data: any) => {
    const url = this.makeBaseUrl("/api/graph");
    const variables = {
      add_data,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.RESEND_VOUCHER,
      "stores",
      { ...variables }
    );
    try {
      const data = await this.makeAuthRequest(payload, url);
      if (data && data.data && data.data.data && data.data.data.resendVoucher) {
        return data.data.data.resendVoucher;
      } else return {};
      // return data
    } catch (error) {
      console.log(error);
      throw error;
      return {};
    }
  };

  getPaypalDetails = async (add_data: any) => {
    const url = this.makeBaseUrl("/api/public/graph");
    const variables = {
      paypalOrder: JSON.stringify(add_data),
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PAYPAL_DETAILS,
      "order",
      { ...variables }
    );
    try {
      const data = await this.makeAuthRequest(payload, url);

      if (data && data.data) {
        return data.data;
      } else return {};
      // return data
    } catch (error) {
      console.log(error);
      throw error;
      return {};
    }
  };

  resendOtp = async (add_data: any) => {
    const url = this.makeBaseUrl("/api/public/graph");
    const variables = {
      add_data: add_data,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.RESEND_OTP,
      "stores",
      { ...variables }
    );
    try {
      const data = await this.makeAuthRequest(payload, url);

      if (data && data.data) {
        return data.data;
      } else return {};
      // return data
    } catch (error) {
      console.log(error);
      throw error;
      return {};
    }
  };

  sendWelcomeMail = async (add_data: any) => {
    const url = this.makeBaseUrl("/api/public/graph");
    let variables = {
      add_data: JSON.stringify(add_data),
    };

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.SEND_WELCOME_MAIL,
      "stores",
      { ...variables }
    );
    try {
      const data = await this.makeAuthRequest(payload, url);
      if (data && data.data) {
        return data.data;
      } else return {};
      // return data
    } catch (error) {
      console.log(error);
      throw error;
      return {};
    }
  };

  getPlumProFilters = async (variables = {}) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_PRO_FILTERS,
      "plumProListing",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getPlumProProducts = async (variables = {}) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_PRO_VOUCHERS,
      "plumProListing",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getPlumProPlans = async (variables = {}) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.PLUM_PRO_PLANS,
      "plumProListing",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  downloadVoucherCatalogue = async (variables = {}) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VOUCHER_DOWNLOAD,
      "plumProListing",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fileDownloadService = async (filename: string) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const variables = {
      add_data: filename,
      skip: true,
    };

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.DOWNLOAD_SERVICE,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  unSubscribeMail = async (unSubscribe: string) => {
    const endPoint = API_ROUTES.DEFAULT_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const variables = {
      add_data: {
        un_subscribe: unSubscribe,
      },
    };

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.UNSUBSCRIBE_MAIL,
      "stores",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getKybStatus = async () => {
    const endPoint = API_ROUTES.DEFAULT_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const variables = {};

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_KYB_STATUS,
      "diy",
      {
        ...variables,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getExperiencesList = async (filtersData: any) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE_LST,
      "experience",
      {
        add_data: filtersData,
      }
    );

    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getExperiencesCitiesList = async (countryCode: string) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE_CITIES_LST,
      "experience",
      {
        countryCode,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getExperiencesCategoriesList = async (countryCode: string) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE_CATEGORIES_LST,
      "experience",
      {
        countryCode,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  fetchStates = async (country_id: number, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const variables = { country_id };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_STATES,
      "stores",
      { ...variables }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  fetchCountries = async (isLoggedIn: boolean = false) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_COUNTRY,
      "stores",
      {}
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  fetchStateCities = async (state_id: number, isLoggedIn: boolean) => {
    const endPoint = isLoggedIn
      ? API_ROUTES.DEFAULT_ENDPOINT
      : API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = this.makeBaseUrl(endPoint);

    const variables = { state_id };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_CITIES,
      "stores",
      { ...variables }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  createExperienceOrder = async (
    inputData: any,
    isLoggedIn: boolean = false
  ) => {
    let url: string = this.makeGraphUrl();
    if (isLoggedIn == false) {
      url = this.makeUrl("");
    }
    const variables = {
      experienceOrder: inputData,
    };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.EXPERIENCE_CREATE_ORDER,
      "experience",
      {
        ...variables,
      }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };
}

const ApiService = new Api();
Object.freeze(ApiService);

export default ApiService;
