import React, { ReactNode } from "react";
import { Container } from "reactstrap";
import MediaQuery from "react-responsive";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

interface LayoutProps {
  noHeader?: boolean;
  noCategory?: boolean;
  customHeaderComponent?: ReactNode;
  paymentHeader?: boolean;
  paymentFooter?: boolean;
  fluid?: boolean;
  nofooter?: boolean;
  withoutContainer?: boolean;
  fixedHeader?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  noHeader,
  customHeaderComponent,
  children,
  noCategory,
  paymentHeader = false,
  paymentFooter,
  fluid,
  nofooter,
  withoutContainer = false,
  fixedHeader = false,
}) => {
  return (
    <>
      {!!noHeader ? null : customHeaderComponent ? (
        customHeaderComponent
      ) : (
        <Header
          paymentHeader={paymentHeader}
          noCategory={noCategory}
          fixedHeader={fixedHeader}
          withoutContainer={withoutContainer}
        />
      )}
      {withoutContainer ? (
        <>{children}</>
      ) : (
        <Container fluid={fluid}>{children}</Container>
      )}
      {!!nofooter ? null : (
        <MediaQuery minDeviceWidth={992}>
          <Footer paymentFooter={paymentFooter} />
        </MediaQuery>
      )}
    </>
  );
};

export default Layout;
